import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isElementNode,
  $isRangeSelection,
  $setSelection,
  ElementFormatType,
  LexicalNode,
  NodeKey,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { triggerAlert } from '../../redux/appSetting/actions';
import { isEmpty } from 'lodash';
import {
  $createTableCellNode,
  $createTableNodeWithDimensions,
  $createTableRowNode,
} from '@lexical/table';

import { $insertNodeToNearestRoot } from '@lexical/utils';
import {
  $createImageNode,
  ImagePayload,
} from 'src/coreUI/textEditorComp/nodes/ImageNode';
import imagePlaceholder from '@summ-ai-github/summ-frontend-package/assets/image_placeholder.png';
import {
  setHiddenTable,
  imageAddedToTable,
  imageRemovedFromTable,
  // resetTableImages,
  selectImagePlace,
  setImageMode,
} from '../../redux/addImage/actions';
import store from '../../redux/store';
import { outputEditor } from './TextareaField';
import { saveImageAddedToTable } from 'src/redux/translation/actions';
import imageCompression from 'browser-image-compression';

export const editorValueClear = (refEditor: any, dispatch: any) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      $getRoot().clear();
    });
  }
};

export const editorValueUpdate = (text: string, refEditor: any, id: string) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      if (text?.includes('</p>')) {
        text = __createparagraphTagsFormBreaks(text);
      }
      // Create a p tag on the fly to check to text format fo lexical
      text = __checkParagraphValidation(text);
      // }
      text = __checkClassName(text);

      if (!isEmpty(text)) {
        __createDomAndUpdate(text, refEditor, id);
      }
    });
  }
};

const __createparagraphTagsFormBreaks = (htmlString: string) => {
  // Create a temporary div to hold the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Get the <p> tag
  const pTag: any = tempDiv.querySelector('p');

  // Get the inner HTML of the <p> tag
  const innerHTML = pTag.innerHTML;

  // Split the inner HTML at <br><br> or \n\n
  const segments = innerHTML?.split(/(<br><br>|\n\n)/);

  // Filter out the <br><br> or \n\n from the segments
  const filteredSegments = segments?.filter(
    (segment: any) => segment !== '<br><br>' && segment !== '\n\n'
  );

  // Create new <p> tags for each segment
  const newPTags = filteredSegments.map((segment: any) => {
    const newP = document.createElement('p');
    newP.innerHTML = segment;
    return newP;
  });

  // Replace the original <p> tag with the new <p> tags
  pTag.replaceWith(...newPTags);

  // Return the modified HTML
  return tempDiv.innerHTML;
};

const __removeAllPlaceholders = (
  nodes: Array<LexicalNode>,
  tableImages: string[],
  dispatch: any
) => {
  let hiddenNodes = nodes
    .filter((n) => n.__type == 'image')
    .map((n) => getRowNode(n)?.__key)
    .filter((k) => k !== undefined && !tableImages.includes(k));

  setHiddenTable(dispatch, hiddenNodes);
};

function __putPlaceholdersBack(
  nodes: Array<LexicalNode>,
  dispatch: any,
  tableImages: string[]
) {
  let hiddenNodes = nodes
    .filter((n) => n.__type === 'header')
    .map((n) => getRowNode(n)?.__key)
    .filter((k) => k !== undefined);

  setHiddenTable(dispatch, hiddenNodes);
}

export function checkIfTablesEnabled() {
  const root = $getRoot();
  let nodes = $getAllNodes(root);

  for (let node of nodes) {
    // if the conent is inside table
    if (node.__type === 'table') {
      return true;
    }
  }
  return false;
}

// convert text to paragraph and table
export const convertParagraphAndTable = (
  refEditor: any,
  sidebarTab: number,
  dispatch: any,
  tableImages: string[]
) => {
  refEditor.current.update(() => {
    // get content
    const root = $getRoot();
    let nodes = $getAllNodes(root);
    let tableContent: boolean = false;

    // clear the editor

    // check if it is a table and if it have image inside it
    tableContent = checkIfTablesEnabled();

    if (sidebarTab === 3) {
      // if content not table conver
      if (!tableContent) {
        __convertFromParagraphToTable(nodes, refEditor, dispatch);
        if (checkIfTablesEnabled()) {
          setImageMode(dispatch, true);
        }
      } else {
        __putPlaceholdersBack(nodes, dispatch, tableImages);

        if (tableImages && tableImages.length > 0) {
          setImageMode(dispatch, true);
        }
      }
    } else if (tableContent && sidebarTab !== 3) {
      if (tableImages.length === 0) {
        // if content is table convert
        __convertFromTableToParagraph(nodes, tableImages);
      } else {
        __removeAllPlaceholders(nodes, tableImages, dispatch);
      }
      setImageMode(dispatch, false);
    }
  });
};

const __convertFromTableToParagraph = (
  nodes: LexicalNode[],
  tableImages: string[]
) => {
  // loop over node and extract text from table
  let finalTableNodes: LexicalNode[] = [];
  const rootNode = $getRoot();
  for (let node of nodes) {
    if (
      ['heading', 'paragraph', 'list', 'code', 'quote'].includes(node.__type)
    ) {
      finalTableNodes.push(node);
    }

    if (tableImages.includes(node.__key)) {
      finalTableNodes.push(node);
    }
  }
  rootNode.clear();
  rootNode.append(...finalTableNodes);
};

const __convertFromParagraphToTable = (
  nodes: LexicalNode[],
  refEditor: any,
  dispatch: any
) => {
  let finalTableNodes: LexicalNode[] = [];
  // editorValueClear(refEditor);
  const rootNode = $getRoot();
  let lastCellNode: any = null;
  let hiddenTable: any = [];
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (
      ['heading', 'paragraph', 'list', 'code', 'quote'].includes(node.__type) &&
      !isEmpty(node.getTextContent()) &&
      node.getTextContent() !== '\n'
    ) {
      if (node.__type === 'list' && lastCellNode != null) {
        lastCellNode.append(node);
      } else {
        const tableNode = $createTableNodeWithDimensions(0, 1);
        if (tableNode) {
          // Create a new row
          const rowNode = $createTableRowNode();

          // Create the second cell text
          const cellNode = $createTableCellNode(0);
          if (node.__type !== 'heading') {
            lastCellNode = cellNode;
          } else {
            lastCellNode = null;
          }
          cellNode.append(node);

          // Append cells to the row
          rowNode.append(cellNode);
          // Append row to the table
          tableNode.append(rowNode);
          // Insert the table into the editor

          finalTableNodes.push(tableNode);

          updateTableColumn(refEditor, '', '', cellNode.__key, dispatch);

          if (node.__type === 'heading') {
            hiddenTable.push(rowNode.__key);
          }
        }
      }
    }
  }
  setHiddenTable(dispatch, hiddenTable);
  rootNode.clear();
  rootNode.append(...finalTableNodes);
  rootNode.selectStart();
};

const __checkParagraphValidation = (text: string) => {
  let finalParagraphs = '';
  // Match pattern for the wrapped html
  const regex = new RegExp(
    /([^>])(ul|(h[1-5])|p|code|ol)(\W+)(.*?)<\/\2>/,
    'gm'
  );
  // Replace the match pattern with special sign
  let remainingText = text?.replace(regex, '$@');
  // Wrap the missing element with p tag
  let filterText = remainingText
    ?.split('$@')
    .filter((item: any) => item !== '');

  let itemValue: { [key: string]: string } = {};
  // if (filterText?.length > 0) {
  //   Sentry.captureMessage(
  //     `Translation job HTML Error format: ${text}`,
  //     'debug'
  //   );
  // }
  filterText?.forEach((item: string) => {
    if (item !== '<br/>' && item !== '<br>') {
      itemValue = {
        [item]: `<p class="editor-paragraph">${item}</p>`,
        ...itemValue,
      };
    }
  });

  // Replace the matching text with the updated one
  let textValueUpdate = text;

  for (let item in itemValue) {
    textValueUpdate = textValueUpdate?.replace(item, itemValue[item]);
  }
  finalParagraphs = finalParagraphs + textValueUpdate;

  return finalParagraphs;
};

export const splitHTMLContentAndCreateTable = (
  refEditor: any,
  text: string,
  switchInputValue: 'on' | 'off'
) => {
  if (switchInputValue === 'off' && !isEmpty(text)) {
    text = text.replaceAll(/<br><br>/g, '</p><p>');
  }
  const splitText = text.split(/(?<=<\/(h1|p|h2|h3|h4|h5|h6|ul|code|ol)>)/g);
  const exclude = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'ul',
    'code',
    'img',
  ];
  for (let item of splitText) {
    if (!exclude.includes(item)) {
      __createTable(refEditor, item);
    }
  }
};

const __createTable = (refEditor: any, text: string, column: number = 1) => {
  if (refEditor.current && text !== '<p class="editor-paragraph"><br/></p>') {
    refEditor.current.update(() => {
      // Create new table
      const tableNode = $createTableNodeWithDimensions(0, column);
      if (tableNode) {
        // Create a new row
        const rowNode = $createTableRowNode();

        // Create the second cell text
        const textCellNode = __createTableTextCellNode(text, refEditor);

        // Append cells to the row
        rowNode.append(textCellNode);
        // Append row to the table
        tableNode.append(rowNode);
        // Insert the table into the editor
        $insertNodeToNearestRoot(tableNode);
      }
    });
  }
};

const __createTableTextCellNode = (text: string, refEditor: any) => {
  // Create the second cell
  const cellNode = $createTableCellNode(0);
  const node = __createHtmlFromText(text, refEditor, 'justify');

  // Create empty text node as a separator
  // const secondParagraphNode = $createParagraphNode();
  // secondParagraphNode.append(
  //   $createTextNode('').setStyle('margin-bottom:1em; display:block;')
  // );
  // secondParagraphNode.setFormat('justify');

  // Insert nodes
  cellNode.append(...node);

  return cellNode;
};

export const getTableRowTextContent = (tableRow: LexicalNode | null) => {
  if (!tableRow) {
    console.error('tableRow is null or undefined.');
    return null;
  }

  // Ensure tableRow is valid and has children
  const cells = tableRow.getChildren();
  if (cells.length === 0) {
    console.error('No cells found in the table node.');
    return null;
  }

  // Get the last row
  const lastCell = cells[cells.length - 1];
  return lastCell.getTextContent();
};

export const __setSelectedRow = (
  refEditor: any,
  tableKey: string | undefined
) => {
  refEditor.current.update(() => {
    if (!tableKey) {
      console.error('tableKey is null or undefined.');
      return;
    }

    const tableNode = $getNodeByKey(tableKey);
    if (!tableNode) {
      console.error('tableNode is null or undefined.');
      return;
    }

    const dispatch = store.dispatch;
    const content = getTableRowTextContent(tableNode);

    if (content) {
      dispatch(selectImagePlace(content, tableNode?.__key));
    }
  });
};

export const getNodeContentAsync = async (nodeId: number) => {
  const refEditor: any = outputEditor;
  if (!refEditor?.current) {
    return null;
  }

  return new Promise<string>((resolve) => {
    refEditor?.current.update(() => {
      const selectedNode = $getNodeByKey(`${nodeId}`);
      const content = getTableRowTextContent(selectedNode);
      resolve(content);
    });
  });
};

export const __createTableImageCellNode = async (
  url: string,
  imageId?: string,
  nodeKey?: NodeKey | undefined,
  refEditor?: any,
  dispatch?: any
) => {
  if (!isEmpty(url) || imagePlaceholder) {
    let image: string | Blob = url;
    let isPlaceholder = !url;
    if (isPlaceholder) {
      image = imagePlaceholder;
    } else if (url.includes('https')) {
      image = url;
    }

    // Step 1: Fetch the image as a blob
    const response = await fetch(image);
    if (!response.ok) throw new Error('Image fetch failed.');

    const blob = await response.blob();

    // Step 2: Convert to File
    const file = new File([blob], 'image.jpg', { type: blob.type });

    // Step 3: Resize
    const options = {
      maxWidthOrHeight: 200,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    let res = URL.createObjectURL(compressedFile);
    // update the redux store with the blob value
    if (url.includes('http'))
      store.dispatch(saveImageAddedToTable({ key: res, value: url }));

    const params: ImagePayload = {
      altText: 'test image', //todo update this name to be image name
      height: 'fit-content',
      src: res,
      imageId: imageId,
      width: 120,
      handleClick: () => __setSelectedRow(refEditor, nodeKey),
      tableNodeKey: parseInt(nodeKey as string, 10),
    };

    if (!isPlaceholder) {
      params.handleDeleteCell = (_: any, imageNodeKey: NodeKey) => {
        dispatch(imageRemovedFromTable(nodeKey!));
        __handleDeleteFirstCell(refEditor, imageNodeKey);
        __createTableImageCellNode('', '', nodeKey, refEditor, dispatch);
        let state = store.getState();
        if (
          !state.translation.showImagePage &&
          state.appSetting.sidebarTab !== 3
        ) {
          let newHiddenTable = [...state.addImage.hiddenTables, nodeKey];
          setHiddenTable(dispatch, newHiddenTable);
        }
      };
    }

    if (nodeKey) {
      refEditor.current.update(() => {
        const cellNode = $createTableCellNode(0);
        const imageNode = $createImageNode(params);
        cellNode.append(imageNode);
        cellNode.setWidth(140);
        const tableNode = $getNodeByKey(nodeKey);
        if (tableNode) {
          tableNode.append(cellNode, tableNode.getFirstChild());
          resetHighlight(refEditor);
        } else {
          console.error('Can not find table node with key: ', nodeKey);
        }

        if (!isPlaceholder) {
          dispatch(imageAddedToTable(nodeKey));
        }
      });
    }
  }
};

function plainBase64ToBlob(base64: string, mimeType = 'image/png') {
  // Decode the base64 string
  const bstr = atob(base64);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // Convert the base64 string to a byte array
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a Blob with the specified MIME type
  return new Blob([u8arr], { type: mimeType });
}

function getRowNode(node: LexicalNode | null) {
  while (node !== null && node.__type !== 'tablerow') {
    node = node.getParent();
  }
  return node;
}

export const updateTableColumn = async (
  refEditor: any,
  url: string,
  imageId?: string,
  selectedNode?: string,
  dispatch?: any
) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      // Get the anchor node (current focus point of the selection)
      let anchorNode = $getNodeByKey(selectedNode ?? '');
      // Traverse upwards to find the table node
      let anchorRowNode = getRowNode(anchorNode);
      $setSelection(null);

      if (anchorRowNode) {
        let children = anchorRowNode.getFirstChild().getFirstChild();
        if (children.__type === 'image') {
          __handleDeleteFirstCell(refEditor, children.__key);
        }

        __createTableImageCellNode(
          url,
          imageId,
          anchorRowNode.__key,
          refEditor,
          dispatch
        );
      }
    });
  }
  // }
};

const __handleDeleteFirstCell = (refEditor: any, nodeKey: NodeKey) => {
  (refEditor.current || refEditor).update(() => {
    if (nodeKey) {
      const selectionNode = $getNodeByKey(nodeKey);
      if (selectionNode) {
        const parentNode = selectionNode?.getParent();
        parentNode?.remove();
      }
    }
  });
};

// const __checkParagraphValidation = (text: string) => {
//   let finalParagraphs = '';
//   // Match pattern for the wrapped html
//   const regex = new RegExp(
//     /([^>]+)(ul|(h[1-5])|p|code|ol|div)(\W+)(.*?)<\/\2>/,
//     'gm'
//   );
//   // Replace the match pattern with special sign
//   let remainingText = text?.replace(regex, '$@');

//   // Wrap the missing element with p tag
//   let filterText = remainingText.split('$@').filter((item: any) => item !== '');

//   let itemValue: { [key: string]: string } = {};
//   if (filterText.length > 0) {
//     Sentry.captureMessage(
//       `Translation job HTML Error format: ${text}`,
//       'debug'
//     );
//   }
//   filterText.forEach((item: string) => {
//     if (item !== '<br/>' && item !== '<br>') {
//       itemValue = {
//         [item]: `<p class="editor-paragraph">${item}</p>`,
//         ...itemValue,
//       };
//     }
//   });

//   // Replace the matching text with the updated one
//   let textValueUpdate = text;

//   for (let item in itemValue) {
//     textValueUpdate = textValueUpdate?.replace(item, itemValue[item]);
//   }
//   finalParagraphs = finalParagraphs + textValueUpdate;

//   return finalParagraphs;
// };

const __checkClassName = (text: string) => {
  const className: { [key: string]: string } = {
    p: 'editor-paragraph', // top level elements, can't be nested
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
    h6: 'editor-heading-h6',
    ul: 'editor-list-ul',
    code: 'editor-code',
    img: 'editor-image',
  };

  for (let classItem in className) {
    const regex = new RegExp(
      // eslint-disable-next-line no-useless-escape
      `<(${classItem})(?!class\s*=\s*"[^"]*"\s*)>(.*?)`,
      'g'
    );

    text = text.replaceAll(
      regex,
      `<${classItem} class="${className[classItem]}">`
    );
    // replace code text to pre as lexical is accepting it like this
    if (/<\/?code>/g.test(text)) {
      text = text.replaceAll(/code>/g, 'pre>');
      text = text.replaceAll(/<code/g, '<pre');
    }
  }

  return text;
};

export const replaceTextLexical = async (
  refEditor: any,
  textToMatch: { [key: string]: string },
  targetSelectedNode?: any,
  offset?: number
) => {
  const sortObjectKey = Object.keys(textToMatch).sort(
    (a, b) => b.length - a.length
  );
  const rgx = new RegExp(
    `\\b(${sortObjectKey
      .map((key) => key?.split('').join('(?:[-·]?)'))
      .join('|')})\\b`,
    'gi'
  );

  // save the selected node in this var so we can put the curoser to it
  let splitedNode: any = null;

  if (sortObjectKey.length > 0) {
    const exception: string[] = ['linebreak', 'paragraph'];
    if (refEditor.current) {
      await refEditor.current.update(() => {
        const root = $getRoot();
        const nodes = $getAllNodes(root);
        for (const node of nodes) {
          if (exception.includes(node.__type) || node.__parent === 'root')
            continue;
          if (node.__type === 'text') {
            const nodeIndexs = __getNodeIndex(
              node,
              rgx,
              targetSelectedNode,
              offset
            );
            if (nodeIndexs) {
              let nodeResult = __nodeSplit(nodeIndexs, node, rgx);
              if (nodeResult.newNodeSelected) {
                splitedNode = nodeResult;
              }
            } else {
              node.setStyle('background-color: rgb(220 226 230 / 3%)');
            }
          }
          // }
        }
      });
    }
    return { splitedNode };
  }
};

export const resetHighlight = (refEditor: any) => {
  const exception: string[] = ['linebreak', 'paragraph'];
  if (refEditor.current) {
    refEditor.current.update(() => {
      const root = $getRoot();
      const nodes = $getAllNodes(root);

      for (const node of nodes) {
        if (exception.includes(node.__type) || node.__parent === 'root')
          continue;
        if (node.__type === 'text') {
          // Remove all the background
          node.setStyle('background-color: rgb(220 226 230 / 3%)');
        }
      }
    });
  }
};

const __nodeSplit = (
  data: {
    resultIndex: number[];
    nodeText: string;
    targetNewNodeIndex: {
      targetIndex: number;
      beforOffeset: number;
    } | null;
  },
  node: any,
  rgx: any
) => {
  const { resultIndex, targetNewNodeIndex } = data;
  const splitNodes = node.splitText(...resultIndex);
  // console.log({ data });
  // let textValue = node.__text;
  // const { currentNode } = selectedTarget;
  let nodeText: string = '';
  node.setTextContent('');
  for (let i = 1; i < splitNodes.length; i++) {
    nodeText = splitNodes[i].__text;
    if (nodeText !== ':') {
      if (rgx.test(nodeText)) {
        splitNodes[i].setStyle(
          'background-color: #edc34775; padding: 2px; border-radius: 3px;'
        );
      } else {
        splitNodes[i].setStyle('background-color: rgb(220 226 230 / 3%)');
      }
      node.insertBefore(splitNodes[i]);
      // if (!firstTime) {
      // $getRoot().selectStart();
      // }
    }
  }
  const newNodeSelected = targetNewNodeIndex?.targetIndex
    ? splitNodes[targetNewNodeIndex.targetIndex + 1]
    : null;
  return { newNodeSelected, targetNewNodeIndex };
};

const __getNodeIndex = (
  node: any,
  rgx: any,
  targetSelectedNode?: any,
  offset?: number
) => {
  let nodeText: string = node.__text;
  let check = nodeText.match(rgx);
  let targetNewNodeIndex: {
    targetIndex: number;
    beforOffeset: number;
  } | null = null;
  if (targetSelectedNode?.__text === nodeText && offset) {
    targetNewNodeIndex = __findSplitIndex(nodeText, rgx, offset);
  }

  if (check?.length) {
    node = node.setTextContent(`:${nodeText}`);
    nodeText = `:${nodeText}`;
    const matches = nodeText?.split(rgx);
    let resultIndex: number[] = [1];
    matches?.forEach((match: any, index: number) => {
      let checkMatch = match.match(rgx);
      if (checkMatch?.length) {
        let setIndex = nodeText.indexOf(
          match,
          resultIndex[resultIndex.length - 1]
        );

        resultIndex = [...resultIndex, setIndex, setIndex + match.length];
      }
    });
    return { resultIndex, nodeText, targetNewNodeIndex };
  }
};

const __findSplitIndex = (text: string, delimiter: string, offset: number) => {
  const words = text.split(delimiter); // Split text into an array
  let currentLength = '';
  let targetIndex = 0;
  for (let i = 0; i < words.length; i++) {
    let newWord = currentLength + words[i];
    if (newWord.length <= offset) {
      currentLength += words[i];
    }

    if (newWord.length >= offset) {
      targetIndex = i;
      break;
    }
  }

  return { targetIndex, beforOffeset: currentLength.length }; // Return -1 if something goes wrong
};

const __createDomAndUpdate = (
  targetText: string,
  refEditor: any,
  id: string
) => {
  const nodes = __createHtmlFromText(
    targetText.replaceAll('\n', '<br/>'),
    refEditor
  );
  $insertNodes(nodes);
  // Make the curoser start at the begining when first time translate
  // if (id === 'translation-input') {
  $getRoot()?.selectStart();
  // }
};

export const __createHtmlFromText = (
  targetText: string,
  refEditor: any,
  paragraphFormat: ElementFormatType = ''
) => {
  // In the browser you can use the native DOMParser API to parse the HTML string.
  const parser = new DOMParser();
  const dom = parser.parseFromString(targetText, 'text/html');
  // Once you have the DOM instance it's easy to generate LexicalNodes.
  const nodes = $generateNodesFromDOM(refEditor.current, dom);
  // $createParagraphNode().setFormat(paragraphFormat);
  return nodes;
};

export function $getAllNodes(root: LexicalNode): Array<LexicalNode> {
  const nodes: any = [];
  let child: LexicalNode | null = root.getFirstChild();
  while (child !== null) {
    nodes.push(child);
    if ($isElementNode(child)) {
      const subChildrenNodes = $getAllNodes(child);
      nodes.push(...subChildrenNodes);
    }
    child = child.getNextSibling();
  }
  return nodes;
}

// export const currentSelected = (refEditor: any) => {
//   if (refEditor.current) {
//     const selection = refEditor?.current?.getEditorSate().read(() => {
//       return $getSelection();
//     });
//     if (selection?.focus?.key) {
//       const currentNode = $getNodeByKey(selection?.focus?.key);
//       return { selection, currentNode };
//     }
//     return null;
//   }
// };
// const __selectNode = (key: string) => {
//   const currentNode = $getNodeByKey(key);
//   const style = currentNode?.getStyle();
//   if (
//     style !== 'background-color: #edc34775; padding: 2px; border-radius: 3px;'
//   ) {
//     currentNode?.select(1, 1);
//   } else {
//     currentNode?.select();
//   }
// };

export const checkInputLength = (
  inputText: string,
  dispatch: any,
  userDetails: any,
  t: any
) => {
  if (inputText.length < 1) {
    dispatch(triggerAlert(t('error.minInputLength'), 'error'));
    return false;
  } else if (inputText.length > 10000) {
    dispatch(triggerAlert(t('error.inputlength'), 'error'));
    return false;
  } else if (
    userDetails &&
    userDetails?.quotaspent + inputText.length > userDetails?.quotalimit &&
    userDetails?.translatorlicence === true &&
    userDetails?.currentlicense !== 'Kostenfreies Paket'
  ) {
    // show quota alert if user is a translator
    dispatch(triggerAlert(t('error.quotaExceeded'), 'error'));
    return false;
  } else if (
    userDetails &&
    userDetails?.quotaspent + inputText.length > userDetails?.quotalimit
  ) {
    dispatch(triggerAlert(t('error.inputLengthLimitPremium'), 'error'));
    return false;
  } else {
    return true;
  }
};

export const heighlightSelection = (
  selection: any,
  refEditor: any,
  text: string,
  insideTextArea: boolean = true
) => {
  if (refEditor.current) {
    refEditor.current.update(() => {
      // let text = selection?.getTextContent();
      const root = $getRoot();
      const allNodes = $getAllNodes(root);
      const exception: string[] = ['linebreak', 'paragraph', 'list'];
      for (const node of allNodes) {
        if (exception.includes(node.__type) || node.__parent === 'root')
          continue;
        // Remove all the background
        if (node.__type === 'text') {
          node.setStyle('background-color: rgb(220 226 230 / 3%)');
        }
      }
      if (!isEmpty(text)) {
        // Clear the already highlighted text

        // select start this will always start from 0 to offset value
        let anchorValue = selection?.anchor;
        let anchorOfffset = anchorValue.offset;
        let anchoreKey = anchorValue.key;

        // select end this will always start from the offset value
        let focusVal = selection?.focus;
        let focusOffset = focusVal.offset;
        let focuseKey = focusVal.key;

        // Selection nodes
        let nodes = selection?.getNodes();
        // Check if the select from the same node
        if (nodes.length === 1) {
          // split the text
          let textSplit = nodes[0]?.splitText(...[focusOffset, anchorOfffset]);
          for (let item of textSplit) {
            if (item.getTextContent() === text) {
              item.setStyle(
                'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
              );
            }
          }
        }

        // Add condition depend if the pointer inside or outside textarea
        let checkTextContent = (node: any) =>
          !insideTextArea ? text.includes(node.__text) : true;

        // Check id the select includes different nodes
        if (nodes.length > 1) {
          // Loop over the node and check the key to split what match user selection
          for (let node of nodes) {
            // If line break skip it
            if (node.__type === 'text') {
              if (node.__key === anchoreKey && checkTextContent(node)) {
                let textSplit = node?.splitText(...[anchorOfffset]);
                let indexItem = text.endsWith(textSplit[0].__text)
                  ? 0
                  : textSplit.length - 1;
                textSplit[indexItem].setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              } else if (node.__key === focuseKey && checkTextContent(node)) {
                let textSplit = node?.splitText(...[focusOffset]);
                // check if select started from the bottom or the top
                let indexItem =
                  text.startsWith(textSplit[0].__text) ||
                  text.endsWith(textSplit[0].__text)
                    ? 0
                    : textSplit.length - 1;
                textSplit[indexItem].setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              } else if (
                node.__key !== anchoreKey &&
                node.__key !== focuseKey
              ) {
                node.setStyle(
                  'background-color: #E3E9ED; padding: 0px; border-radius: 3px;'
                );
              }
            }
          }
        }

        nodes[0]?.select();
      }
    });
  }
};

export const getSelectedText = (
  refEditor: any,
  editorValueUpdate: any,
  highlightedWord: { [key: string]: string }
) => {
  refEditor.read(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchor = selection.anchor; // Cursor position
      const focus = selection.focus; // Cursor position (if range is selected)

      // Get the text node at the cursor position
      const textNode = anchor.getNode();
      const textContent = textNode.getTextContent();

      // Get the cursor offset within the text node
      const cursorOffset = anchor.offset;

      // Extract the word at the cursor position
      const words = textContent.split(/\s+/);
      let wordStart = 0;
      let wordEnd = 0;
      let currentWord = '';

      for (const word of words) {
        wordEnd = wordStart + word.length;
        if (cursorOffset >= wordStart && cursorOffset <= wordEnd) {
          currentWord = word;
          break;
        }
        wordStart = wordEnd + 1; // +1 for the space
      }

      if (currentWord) {
        // Update the style of the word
        editorValueUpdate.update(() => {
          const textNode = anchor.getNode();
          const textContent = textNode.getTextContent();

          // Split the text content into parts
          const beforeWord = textContent.slice(0, wordStart);
          const afterWord = textContent.slice(wordEnd);

          // Create new styled nodes
          const beforeNode = $createTextNode(beforeWord);
          const wordNode = $createTextNode(currentWord);
          wordNode.setStyle(
            `background-color: ${
              highlightedWord[currentWord]
                ? '#edc34775; padding: 2px; border-radius: 3px;'
                : '#E3E9ED; padding: 0px; border-radius: 3px;'
            } `
          ); // Apply custom style
          const afterNode = $createTextNode(afterWord);

          // Replace the original text node with the new nodes
          const paragraphNode = $createParagraphNode();
          paragraphNode.append(beforeNode, wordNode, afterNode);
          textNode.replace(paragraphNode);
        });
      }
    }
  });
};
