import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  generateImages,
  feedbackImageUpload,
  setImageGenerationText,
  insertImageToTable,
} from '../../redux/addImage/actions';
import { AddImageSelector } from '../../redux/addImage/selector';
import { TranslateSelector } from '../../redux/translation/selector';
import ImageGenerationComp from '@summ-ai-github/summ-frontend-package/components/imageGenerationComp/ImageGenerationComp';

type Props = {
  isExpanded: boolean;
};

const ImageGenerationWrapper = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const tempImageList = useSelector(
    (state: any) => AddImageSelector(state).tempImageList,
    shallowEqual
  );

  const generatedImages = useSelector(
    (state: any) => AddImageSelector(state).generatedImages,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => AddImageSelector(state).generationLoading,
    shallowEqual
  );

  const uploadedImageList = useSelector(
    (state: any) => AddImageSelector(state).uploadedImageList
  );

  const deletedImgId = useSelector(
    (state: any) => AddImageSelector(state).deletedImgId
  );
  const selectedOutputNode = useSelector(
    (state: any) => TranslateSelector(state).selectedOutputNode,
    shallowEqual
  );

  const imageGenerationText = useSelector(
    (state: any) => AddImageSelector(state).imageGenerationText
  );

  return (
    <ImageGenerationComp
      searchUploadedImages={(value: any) => dispatch(generateImages(value))}
      generatedImages={generatedImages}
      tempImageList={tempImageList}
      loading={loading}
      langWords={{
        ListUploadTitle: t('ListUploadTitle'),
        searchWord: t('image.generateImages'),
        searchImage: t('searchImage'),
        myGallary: t('myGallary'),
        bilder: t('bilder'),
        licenseImage: t('licenseImage'),
        uploading: t('uploading'),
        dragUploadImage: t('dragUploadImage'),
        dragAndDropSplitterText: t('input.dragAndDropSplitterText'),
        buttonUpload: t('buttonUpload'),
        noImage: t('noImage'),
        imageSearchHint: t('image.generateHintText'),
        warning: t('image.noTextWarning'),
      }}
      handleInsertImage={(url: string, imageId?: string) =>
        dispatch(insertImageToTable(url, imageId))
      }
      feedbackImageUpload={(
        id: string,
        feedback: 0 | 1 | 2,
        imageId: number,
        isOwner: boolean
      ) => dispatch(feedbackImageUpload(id, feedback, imageId, isOwner))}
      selectedOutputNode={selectedOutputNode}
      uploadedImageList={uploadedImageList}
      deletedImgId={deletedImgId}
      isExpanded={props.isExpanded}
      imageGenerationText={imageGenerationText}
      setImageGenerationText={(value: string) =>
        dispatch(setImageGenerationText(value))
      }
    />
  );
};

export default ImageGenerationWrapper;
