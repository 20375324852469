import { combineReducers } from 'redux';
import feedbackReducer from './feedback/reducer';
import appSettingReducer from './appSetting/reducer';
import userReducer from './user/reducer';
import supabaseReducer from './supabase/reducer';
import glossaryReducer from './glossary/reducer';
import synonymsReducer from './synonyms/reducer';
import summaryReducer from './summary/reducer';
import transHistoryReducer from './translationHistory/reducer';
import glossaryEntriesReducer from './glossaryEntries/reducer';
import translateReducer from './translation/reducer';
import addImageReducer from './addImage/reducer';
import featuresReducer from './features/reducer';
import userPrefillReducer from './orderForm/reducer';
import myGalleryReducer from './myGallery/reducer';
import untranslatedWordReducer from './untranslatedWord/reducer';
import chatbotReducer from './chatbot/reducer';
import { clearSessionandLocalStorge } from 'src/utils/clearStorage';
import { RESET_APP_SETTING } from './appSetting/types';
import { LOGOUT } from './supabase/types';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

const appSettingPersistConfig = {
  key: 'appSetting',
  storage,
  whitelist: ['sidebarTab', 'textareaDimension', 'historyTransSetting'],
};

const appReducer: any = combineReducers({
  feedback: feedbackReducer,
  appSetting: persistReducer(appSettingPersistConfig, appSettingReducer),
  user: userReducer,
  supabase: supabaseReducer,
  glossary: glossaryReducer,
  synonyms: synonymsReducer,
  summary: summaryReducer,
  transHistory: transHistoryReducer,
  glossaryEntries: glossaryEntriesReducer,
  translation: translateReducer,
  addImage: addImageReducer,
  features: featuresReducer,
  userPrefill: userPrefillReducer,
  myGallery: myGalleryReducer,
  untranslatedWords: untranslatedWordReducer,
  chatbot: chatbotReducer,
});

const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === LOGOUT) {
    clearSessionandLocalStorge();
    return appReducer(undefined, action);
  }

  if (action.type === RESET_APP_SETTING) {
    return appReducer(undefined, action); // Reset state by passing `undefined`
  }

  return appReducer(state, action);
};

export default rootReducer;
