import Sentry from 'src/config/sentryConfig';
import feedbackService from '../../services/feedback.service';
import { FeedbackFormInput } from '../../types/feedback.types';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  SEND_FEEDBACK,
  LOADING,
  STOP_LOADING,
  SET_RATING_MSG,
  GET_RATING_FEEDBACK,
  SET_RATING_FEEDBACK,
  SET_RATING_SNACKBAR_KEY,
  SET_RATING_FEEDBACK_STEP,
} from './types';
import { SnackbarKey } from 'notistack';

export const sendFeedback =
  (data: FeedbackFormInput) => async (dispatch: any) => {
    try {
      const res = await feedbackService.createFeedback(data);
      dispatch({
        type: SEND_FEEDBACK,
        payload: res,
      });
      return appMessageHandling(
        dispatch,
        'success.create_feedback_success',
        'success'
      );
    } catch (e: any) {
      return appMessageHandling(
        dispatch,
        'error.create_feedback_error',
        'error'
      );
    }
  };

export const getRatingFeedback = () => async (dispatch: any) => {
  dispatch({ type: LOADING });
  try {
    const res = await feedbackService.getRatingFeedbackApi();
    dispatch({
      type: GET_RATING_FEEDBACK,
      payload: res.data,
    });
  } catch (e: any) {
    return Sentry.captureMessage(`Get rating feedback error: ${e}`, 'error');
  } finally {
    return dispatch({ type: STOP_LOADING });
  }
};

export const setRatingFeedback =
  (data: { status?: 'done' | 'canceled'; rating?: string; message?: string }) =>
  async (dispatch: any, getState: any) => {
    const rating = getState().feedback.ratingVal;
    try {
      const res = await feedbackService.setRatingFeedbackApi({
        ...data,
        rating,
      });
      return dispatch({
        type: SET_RATING_FEEDBACK,
        payload: res.data,
      });
    } catch (e: any) {
      return appMessageHandling(
        dispatch,
        'error.create_feedback_error',
        'error'
      );
    }
  };
export const setRatingMsg =
  (data: { msg: string; rating: number }) => (dispatch: any) => {
    return dispatch({
      type: SET_RATING_MSG,
      payload: data,
    });
  };

export const setRatingFeedbackStep = (step: number) => (dispatch: any) => {
  localStorage.setItem('ratingStep', step.toString());
  return dispatch({
    type: SET_RATING_FEEDBACK_STEP,
    payload: step,
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const setRatingSnackbarKey = (key: SnackbarKey) => (dispatch: any) => {
  return dispatch({
    type: SET_RATING_SNACKBAR_KEY,
    payload: key,
  });
};
