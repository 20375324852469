export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const GET_ALL_UPLOADED_IMAGE = 'GET_ALL_UPLOADED_IMAGE';
export const SEARCH_UPLOADED_IMAGE = 'SEARCH_UPLOADED_IMAGE';
export const SEARCHED_IMAGES_RECEIVED = 'SEARCHED_IMAGES_RECEIVED';
export const SET_ALL_IMAGE_SEARCH_RESULT = 'SET_ALL_IMAGE_SEARCH_RESULT';
export const UPLOAD_LIST_IMAGE = 'UPLOAD_LIST_IMAGE';
export const SEARCH_LOADING = 'LOADING_IMAGE_UPLOAD';
export const GENERATION_LOADING = 'LOADING_IMAGE_GENERATION';
export const STOP_SEARCH_LOADING = 'STOP_LOADING_IMAGE_UPLOAD';
export const STOP_GENERATION_LOADING = 'STOP_LOADING_IMAGE_GENERATION';
export const RESET_IMAGE_UPLOAD_STATE = 'RESET_IMAGE_UPLOAD_STATE';
export const SET_UPLOAD_IMAGE_LIST = 'SET_UPLOAD_IMAGE_LIST';
export const FEEDBACK_IMAGE = 'FEEDBACK_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_SET = 'DELETE_IMAGE_SET';
export const GENERATED_IMAGE_RECEIVED = 'GENERATED_IMAGE_RECEIVED';
export const SET_IMAGE_SEARCH_TEXT = 'SET_IMAGE_SEARCH_TEXT';
export const SET_IMAGE_GENERATION_TEXT = 'SET_IMAGE_GENERATION_TEXT';
export const IMAGE_ADDED_TO_TABLE = 'IMAGE_ADDED_TO_TABLE';
export const IMAGE_REMOVED_FROM_TABLE = 'IMAGE_REMOVED_FROM_TABLE';
export const RESET_TABLE_IMAGES = 'RESET_TABLE_IMAGES';
export const SET_HIDDEN_TABLE = 'SET_HIDDEN_TABLE';
export const SET_IMAGE_MODE = 'SET_IMAGE_MODE';
