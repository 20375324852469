import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import store from 'src/redux/store';
import { uniqueId } from 'lodash';

export const downloadImagesAsZip = async (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const zip = new JSZip();
  const folder = zip.folder(localStorage.getItem('filename') || 'summ-ai');

  const images = doc.querySelectorAll('img');
  let count = 1;
  const processingImages = Array.from(images).map(async (img) => {
    let src = img.src;

    if (src.startsWith('blob:http')) {
      try {
        let selectedImg = store.getState().translation.selectedImg;
        if (selectedImg[src]) {
          const response = await fetch(selectedImg[src]);
          const blob = await response.blob();
          const id = uniqueId('summ-ai-');
          folder?.file(`${id}.png`, blob);
          count++;
        }
      } catch (error) {
        console.error('Error processing image:', error);
      }
    }
  });

  await Promise.all(processingImages);

  // Generate and download the ZIP
  if (count > 1) {
    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      saveAs(zipFile, `${localStorage.getItem('filename') || 'summ-ai'}.zip`);
      localStorage.removeItem('filename');
    });
  } else {
    alert('No base64 images found.');
  }
};
