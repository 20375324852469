import { useContext } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RatingContext } from './RatingSnackbar';

type Props = {};

const RatingRefer = (props: Props) => {
  const { t } = useTranslation('common');
  const { handleDismiss } = useContext(RatingContext);

  const handleEmailClick = async () => {
    const body = `Hallo [NAME],<br/><br/>

    ich wollte dir kurz ein Tool empfehlen, das ich super hilfreich finde: <b>SUMM AI</b>.<br/>
    Es übersetzt Texte automatisch in <b>Leichte & Einfache Sprache</b> und macht damit meine Texte verständlich für alle.<br/><br/>

    <b>Was das Besondere ist:</b><br/>
    <ul>
      <li>Du kannst es sowohl im Browser, in Word als auch per Schnittstelle nutzen.</li>
      <li>Es ist einfach zu bedienen und spart super viel Zeit.</li>
    </ul><br/>

    Ich hoffe, es ist auch etwas für dich!<br/>
    Für mehr Infos kannst du dir einfach ein kostenloses Beratungsgespräch dort buchen:<br/>

    👉 <a href="https://summ-ai.com/gespraech-vereinbaren/">SUMM AI Beratungsgespräch</a><br/><br/><br/>

    Viele Grüße,<br/>
    [NAME ABSENDER]`;

    try {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([body], { type: 'text/plain' }),
        'text/html': new Blob([body], { type: 'text/html' }),
      });
      await navigator?.clipboard?.write([clipboardItem]);
    } catch (err) {
      await navigator?.clipboard?.writeText(body);
    }

    handleDismiss();
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        {' '}
        <Typography
          variant="body2"
          gutterBottom
        >
          {t('recommend_info')}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
        >
          {t('recommendation_benefit')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        textAlign={'end'}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleEmailClick}
          sx={{ p: '5px 20px' }}
        >
          {t('recommend_now')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RatingRefer;
