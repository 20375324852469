export const downloadBase64Image = (
  imgData: string,
  filename: string = 'SUMMAI'
) => {
  const link = document.createElement('a');
  link.download = filename;
  if (!imgData.includes('https')) {
    // Convert base64 to Blob
    const byteCharacters = atob(
      `data:image/png;base64,${imgData}`.split(',')[1]
    );
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' }); // Change 'image/png' to the appropriate MIME type

    // Create a link element
    link.href = URL.createObjectURL(blob);

    // Trigger the download
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
  } else {
    link.href = imgData;
    // Trigger the download
    link.click();
  }
};
