import React from 'react';
import { Grid, useTheme } from '@mui/material';
import ImagePageListComp from '../imageSearchComp/ImagePageListComp';
import { ImageGenerationWrapperProps } from './imageGeneration.types';
import ImagePageSearchInputComp from '../../coreUI/searchComp/ImagePageSearchInputComp';
import CardCover from '@mui/joy/CardCover';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { downloadBase64Image } from '../../utils/downloadBase64img';
import SearchHintBox from '../imageUploadComp/SearchHintBox';

const ImageGenerationComp = ({
  searchUploadedImages,
  generatedImages,
  loading,
  langWords,
  tempImageList,
  feedbackImageUpload,
  selectedOutputNode,
  uploadedImageList,
  deletedImgId,
  handleInsertImage,
  isExpanded,
  imageGenerationText,
  setImageGenerationText,
}: ImageGenerationWrapperProps) => {
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const handleSubmitPrompt = (value: string) => {
    searchUploadedImages(value);
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      id="image-generation-box"
    >
      {loading && (
        <CardCover
          sx={{
            background: theme.palette.general.blockingOverlay,
            zIndex: 2,
          }}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <LinearProgress
              sx={{
                position: 'absolute',
                width: '60%',
                background: theme.palette.secondary.main,
                '& .MuiLinearProgress-bar': {
                  background: theme.palette.primary.main,
                },
              }}
              aria-label={t('image.generating')}
              id="image-generation-progress"
            />
          </Box>
        </CardCover>
      )}
      {isExpanded && (
        <Grid
          item
          xs={12}
        >
          <ImagePageSearchInputComp
            handleSubmit={handleSubmitPrompt}
            loading={loading}
            placeholder={langWords.searchImage}
            searchWord={langWords.searchWord}
            text={imageGenerationText}
            setText={setImageGenerationText}
            isVisible={isExpanded}
            inputId="ai-image-search-input"
            buttonId="ai-image-search-button"
            warningText={langWords.warning}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        {!generatedImages && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              position: 'relative',
            }}
          >
            <SearchHintBox
              langWords={langWords}
              light={false}
            />
          </Box>
        )}
        {generatedImages && Object.values(generatedImages).length === 0 && (
          <div style={{ color: theme.palette.general.white }}>
            {langWords.noImage}
          </div>
        )}
        {generatedImages && Object.values(generatedImages).length > 0 && (
          <ImagePageListComp
            imageItem={{
              id: 0,
              name: 'Sample Image',
              owner_email: 'owner@example.com',
              source_type: 2,
              is_owner: false,
              website: null,
              is_public: false,
              license: null,
              images: generatedImages?.map((gi) => ({
                uuid: gi.id,
                similarity: 0.85,
                feedback: 1,
                content_base64: gi.base64,
              })),
            }}
            key={0}
            downloadUploadedImage={(base64Id: string) => {
              downloadBase64Image(base64Id);
              // console.log('downloadUploadedImage', base64Id);
            }}
            feedbackImageUpload={feedbackImageUpload}
            deleteUploadedImage={(_) => {}}
            handleInsertImage={handleInsertImage}
            selectedOutputNode={selectedOutputNode}
            langWords={langWords}
            deletedImgId={deletedImgId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ImageGenerationComp;
