import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isTableNode } from '@lexical/table';
import { $getNearestNodeFromDOMNode, $setSelection } from 'lexical';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';
import IconButton from '@mui/material/IconButton';
import {
  DELETE_TABLE_COMMAND,
  SPLIT_TABLE_COMMAND,
} from '../splitAndMergeText';
import { Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const BUTTON_SIZE_PX = 25; // Button size

function TableHoverButtonContainer({
  anchorElem,
}: {
  anchorElem: HTMLElement;
}) {
  const { t } = useTranslation('easy_language');
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();
  const [isShown, setIsShown] = useState(false);
  const [translationContainer, setTranslationContainer] =
    useState<HTMLElement | null>(null);
  const [position, setPosition] = useState<{
    top: number;
    left: number;
    bottom: number;
  } | null>(null);
  const tableDOMNodeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    // Wait for the "translation-output-container" element in the DOM
    const observer = new MutationObserver(() => {
      const container: any = document.getElementById(
        'translation-output-container'
      );
      const hasTable = container?.querySelector('table');
      if (container && hasTable) {
        setIsShown(true);
        setTranslationContainer(container);
        // observer.disconnect(); // Stop observing once found
      } else {
        setIsShown(false);
      }
    });
    // Start observing mutations
    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      const { isOutside, tableDOMNode } = getMouseInfo(event);
      if (isOutside) {
        // setIsShown(false);
        return;
      }
      if (!tableDOMNode) return;

      if (tableDOMNodeRef.current !== tableDOMNode) {
        tableDOMNodeRef.current = tableDOMNode;
      }

      let tableDOMElement: HTMLElement | null | any = null;
      editor.update(() => {
        const maybeTable = $getNearestNodeFromDOMNode(tableDOMNode);
        if ($isTableNode(maybeTable)) {
          if (
            sessionStorage.getItem('tableDOMElement') !== maybeTable.getKey()
          ) {
            tableDOMElement = editor.getElementByKey(maybeTable.getKey());
            // $setSelection(null);
            sessionStorage.setItem('tableDOMElement', maybeTable.getKey());
          }
        }
      });

      if (tableDOMElement) {
        const { width: tableWidth, height: tableHeight } =
          tableDOMElement.getBoundingClientRect();
        setPosition({
          top: tableDOMElement.offsetTop + tableHeight - BUTTON_SIZE_PX - 20,
          bottom: tableDOMElement.offsetTop,
          left: tableWidth - BUTTON_SIZE_PX,
        });
        // setIsShown(true);
      }
    },
    [editor]
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  useEffect(() => {
    return () => {
      if (tableDOMNodeRef.current) {
        tableDOMNodeRef.current = null;
      }
    };
  }, []);

  const handleButtonClick = () => {
    editor.dispatchCommand(SPLIT_TABLE_COMMAND, null);
  };

  const handleDeleteTable = () => {
    editor.dispatchCommand(DELETE_TABLE_COMMAND, null);
  };

  if (
    tableDOMNodeRef.current &&
    position &&
    isShown &&
    translationContainer // Ensures translation container exists before rendering
  ) {
    return createPortal(
      <>
        <Tooltip title={t('split_table')}>
          <IconButton
            style={{
              position: 'absolute',
              top: position.top + 15,
              left: position.left + 25,
              zIndex: 10000,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              width: `${BUTTON_SIZE_PX}px`,
              height: `${BUTTON_SIZE_PX}px`,
              backgroundColor: theme.palette.secondary.main,
              cursor: 'pointer',
            }}
            onClick={handleButtonClick}
            className="table-hover-button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('remove_table')}>
          <IconButton
            style={{
              position: 'absolute',
              top: position.bottom - 15,
              left: position.left + 35,
              zIndex: 10000,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '5px',
              width: `15px`,
              height: `15px`,
              backgroundColor: theme.palette.secondary.main,
              cursor: 'pointer',
            }}
            onClick={handleDeleteTable}
            className="table-hover-button"
          >
            <CloseIcon
              color="primary"
              style={{ fontSize: '15px' }}
            />
          </IconButton>
        </Tooltip>
      </>,
      translationContainer
    );
  }
  return null;
}

export function getMouseInfo(event: MouseEvent): {
  tableDOMNode: HTMLElement | null;
  isOutside: boolean;
} {
  const target = event.target as HTMLElement | null;
  if (target) {
    const tableDOMNode = target.closest<HTMLElement>('table');
    const isOutside = !tableDOMNode;
    return { tableDOMNode, isOutside };
  }
  return { tableDOMNode: null, isOutside: true };
}

export default function TableHoverButtonPlugin({
  anchorElem = document.body,
}: {
  anchorElem?: HTMLElement;
}) {
  return createPortal(
    <TableHoverButtonContainer anchorElem={anchorElem} />,
    anchorElem
  );
}
