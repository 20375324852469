import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { Grid, Container, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CardCover from '@mui/joy/CardCover';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { UserSelector } from '../../../redux/user/selector';
import TextEditorComp from '../../../coreUI/textEditorComp/TextEditorComp';
import Tooltip from '@mui/material/Tooltip';
import { glossarySelector } from 'src/redux/glossary/selector';
import {
  checkIfTablesEnabled,
  convertParagraphAndTable,
  editorValueClear,
  replaceTextLexical,
  resetHighlight,
} from '../helper';
import { configSelector } from 'src/redux/appSetting/selector';
import SelectLangMenu from './SelectLangMenu';
import InfoIcon from './InfoIcon';
import { TranslateSelector } from 'src/redux/translation/selector';
import { useTranslation } from 'react-i18next';
import SettingCompWrapper from '../settingOptions/SettingCompWrapper';
import { updateUserInfo } from 'src/redux/user/actions';
import { AddImageSelector } from '../../../redux/addImage/selector';
import { $getSelection, $getRoot } from 'lexical';
import { debounce } from 'lodash';
import { setImageMode } from '../../../redux/addImage/actions';

type Props = {
  handleOutputLanguageTypeChange: (value: 'easy' | 'plain' | undefined) => void;
  outputLanguage: any;
  showHighlight: boolean;
  open: any;
  outputRefEditor: any;
  showTooltipOutput: any;
  loadingTranslation: boolean;
  setOutputText: any;
  outputText: string;
  outputTextValue: any;
  placeholder: string;
  jobId: string;
  isUpdated: boolean | undefined;
  setIsUpdated: Dispatch<SetStateAction<boolean>>;
};
export const TranslationOutputContext = createContext<any>({});
const TranslationOutput = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  // const [outputFixedValue, setOutputFixedValue] = useState('');
  const matchesSM = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch: any = useDispatch();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));
  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );

  const [textValue, setTextValue] = useState<string>('');

  const userPermissionsEasy = useSelector(
    (state: any) => UserSelector(state).userPermissionsEasy,
    shallowEqual
  );
  const userPermissionsPlain = useSelector(
    (state: any) => UserSelector(state).userPermissionsPlain,
    shallowEqual
  );

  const highLightWord = useSelector(
    (state: any) => glossarySelector(state).highLightWord
  );

  const sidebarTab = useSelector(
    (state: any) => configSelector(state).sidebarTab,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  useEffect(() => {
    let langVal = userDetails?.preferred_output_language_type || 'easy';
    let haveAll = userPermissionsEasy && userPermissionsPlain;

    if (haveAll) {
      return props.handleOutputLanguageTypeChange(langVal);
    } else if (userPermissionsEasy) {
      return props.handleOutputLanguageTypeChange('easy');
    } else if (userPermissionsPlain) {
      return props.handleOutputLanguageTypeChange('plain');
    } else if (!haveAll) {
      return props.handleOutputLanguageTypeChange('easy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPermissionsEasy, userPermissionsPlain]);

  const value = useMemo(() => {
    return {
      outputLanguage: props.outputLanguage,
      handleOutputLanguageTypeChange: props.handleOutputLanguageTypeChange,
    };
  }, [props.outputLanguage, props.handleOutputLanguageTypeChange]);

  const handleHighlight = useCallback(() => {
    if (highLightWord) {
      if (
        Object.keys(highLightWord)?.length > 0 &&
        props.showHighlight &&
        props.open &&
        sidebarTab === 0
      ) {
        return replaceTextLexical(props.outputRefEditor, highLightWord);
      } else if (!props.open || Object.keys(highLightWord)?.length === 0) {
        return resetHighlight(props.outputRefEditor);
      }
    }
  }, [
    highLightWord,
    props.open,
    props.outputRefEditor,
    props.showHighlight,
    sidebarTab,
  ]);

  useEffect(() => {
    handleHighlight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highLightWord, props.outputRefEditor]);

  const tableImages = useSelector(
    (state: any) => AddImageSelector(state).tableNodesWithImages,
    shallowEqual
  );

  const showImagePage = useSelector(
    (state: any) => TranslateSelector(state).showImagePage,
    shallowEqual
  );

  const [showImagePlaceholders, setShowImagePlaceholders] = useState<boolean>();

  useEffect(() => {
    if (showImagePage && showImagePlaceholders) {
      return;
    }

    setShowImagePlaceholders(showImagePage);
    convertParagraphAndTable(
      props.outputRefEditor,
      showImagePage ? 3 : 0,
      dispatch,
      tableImages
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outputRefEditor, showImagePage]);

  useEffect(() => {
    if (transjob.jobId === '-1') {
      editorValueClear(props.outputRefEditor, dispatch);
    }

    setImageMode(dispatch, false);
  }, [dispatch, props.outputRefEditor, transjob.jobId, props.outputText]);

  // Highlight word or remove highlight
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleStorageChange = useCallback(
    debounce(() => {
      if (
        props.showHighlight &&
        Object.keys(highLightWord)?.length > 0 &&
        sidebarTab === 0 &&
        props.open
      ) {
        props.outputRefEditor?.current?.update(async () => {
          const selection: any = $getSelection();
          await replaceTextLexical(
            props.outputRefEditor,
            highLightWord,
            $getRoot().getChildren(),
            selection.anchor.offset
          );
        });
      }
    }, 300), // Adjust the debounce delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      highLightWord,
      props.open,
      props.outputRefEditor,
      props.showHighlight,
      sidebarTab,
    ]
  );

  useEffect(() => {
    debouncedHandleStorageChange();
    return () => debouncedHandleStorageChange.cancel(); // Cleanup debounce on unmount
  }, [textValue, debouncedHandleStorageChange]);

  return (
    <TranslationOutputContext.Provider value={value}>
      <Container
        sx={{
          padding: matchesSM ? '0 !important' : 'auto',
          margin: 0,
          maxWidth: '100% !important',
          paddingLeft: matchesMD ? 'auto' : '12px !important',
          ...(!matchesMD && { paddingLeft: '27px', paddingRight: '21px' }),
        }}
      >
        <Form.Group controlId="translationOutput">
          <Grid
            item
            container
            xs={12}
            sx={{
              justifyContent: 'space-between',
              marginBottom: '0.2rem !important',
            }}
            id="info-header-input"
          >
            <InfoIcon />
            <SelectLangMenu />
          </Grid>

          <Tooltip
            title={
              hasGroup
                ? props.showTooltipOutput
                  ? t('output.tooltip')
                  : undefined
                : t('output.hasNoGroup')
            }
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '400px',
                  padding: '8px 12px',
                  fontSize: '14px',
                },
              },
            }}
            placement="top"
            {...(hasGroup && {
              open: props.showTooltipOutput,
            })}
            disableTouchListener={hasGroup}
            disableInteractive={hasGroup}
            disableFocusListener={hasGroup}
          >
            <Grid
              item
              xs={12}
              sx={{ position: 'relative' }}
            >
              {props.loadingTranslation && (
                <CardCover
                  sx={{
                    background: theme.palette.general.overlay,
                    zIndex: 2,
                  }}
                >
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <LinearProgress
                      sx={{
                        position: 'absolute',
                        width: '60%',
                        background: theme.palette.primary.main,
                        '& .MuiLinearProgress-bar': {
                          background: theme.palette.secondary.main,
                        },
                      }}
                      aria-label={t('translation_loading')}
                    />
                  </Box>
                </CardCover>
              )}
              <TextEditorComp
                disableInputField={props.loadingTranslation}
                inputText={props.outputText}
                refEditor={props.outputRefEditor}
                containerStyle={{ margin: 0 }}
                textValue={props.outputTextValue}
                clearEditor={false}
                placeholder={props.placeholder}
                loader={true}
                id={'translation-output'}
                showToolbar={true}
                settingComponent={<SettingCompWrapper />}
                showFormatIcon={false}
                editable={hasGroup}
                showCounter={transjob.jobId === '-1' ? false : true}
                counterTitle={t('charactercount')}
                complexityScore={
                  false && transjob?.text_metrics
                    ? `${Math.round(
                        transjob.text_metrics.output_lix_score
                      )} (${t(transjob.text_metrics.output_lix_score_display)})`
                    : ''
                }
                complexityScoreTitle={t('complexityScoreTitle')}
                ariaLabel={t('textfield_output')}
                switchValue={userDetails?.format_output_switch}
                handleSwitchFormatting={(value) =>
                  dispatch(updateUserInfo({ format_output_switch: value }))
                }
                highLightWord={highLightWord}
                replaceTextLexical={replaceTextLexical}
                sidebarTab={sidebarTab}
                sidebarOpen={props.open}
                showHighlight={props.showHighlight}
                resetHighlight={resetHighlight}
                setTextValue={setTextValue}
              />
            </Grid>
          </Tooltip>
        </Form.Group>
      </Container>
    </TranslationOutputContext.Provider>
  );
};

export default TranslationOutput;
