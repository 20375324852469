import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo_dark.svg';
import { useState } from 'react';
import MenuListComp from '../../coreUI/menuComp/MenuListComp';
import { pages, PagesObject, profileMenu } from './fixedData';
import { useLocation, useNavigate } from 'react-router-dom';
import RequestReviewPopup from '../Dashboard/Modals/RequestReviewPopup';
import theme from '../../theme/themeConfig';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { UserSelector } from 'src/redux/user/selector';
import LanguageBtnComp from './LanguageBtnComp';
import { FeatureSelector } from 'src/redux/features/selector';
import FeatureComp from 'src/coreUI/featureComp/FeatureComp';
import { updateFeature } from 'src/redux/features/actions';

type Props = {};

const MyNavbarComp = (props: Props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const { t } = useTranslation('common');
  const history = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const [showRequestReviewPopup, setShowRequestReviewPopup] = useState(false);
  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );
  const session = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const userDetailsInfo = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );
  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const featuresList = useSelector(
    (state: any) => FeatureSelector(state).featuresList,
    shallowEqual
  );

  const closeRequestReviewPopup = () => {
    setShowRequestReviewPopup(false);
  };
  const handleRequestReviewButtonClicked = () => {
    setShowRequestReviewPopup(!showRequestReviewPopup);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (link: string) => {
    history(link);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: theme.palette.general.silverBlue,
        zIndex: 10000,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          padding: '10px 16px 10px 10px !important',
          margin: 0,
          maxWidth: '100% !important',
        }}
      >
        <Toolbar
          disableGutters
          sx={{ minHeight: 'fit-content !important' }}
        >
          <Link to="/">
            <img
              id="summ-ai-navbar-logo"
              alt="Logo von SUMM AI"
              src={logo}
              height="40rem"
              className="d-inline-block align-top m-2"
            />
          </Link>

          {/* display menu for mobile */}
          {userDetailsInfo?.betauser &&
            supabaseUser &&
            userDetailsInfo?.hasactivelicence &&
            session && (
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label={t('curent_user_icon')}
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon sx={{ color: theme.palette.primary.main }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                  aria-label={t('user_menu')}
                >
                  {pages().map(
                    (item: PagesObject | null, index: number) =>
                      item && (
                        <MenuItem
                          key={index + 1}
                          onClick={() => handleNavigate(item.link)}
                          sx={{
                            color: theme.palette.primary.main,
                            background:
                              location.pathname === item.link
                                ? theme.palette.general.goldenYellow
                                : 'default',
                            '&:hover': {
                              background:
                                location.pathname === item.link
                                  ? theme.palette.general.goldenYellow
                                  : 'default',
                              color: theme.palette.primary.main,
                            },
                            fontSize: '1rem',
                          }}
                        >
                          <Typography textAlign="center">
                            {t(item.text)}
                          </Typography>
                          <Box
                            component="div"
                            sx={{
                              width:
                                location.pathname === item.link ? '100%' : '0%',
                              height: '2px',
                              transition: '.2s linear',
                              background: theme.palette.general.goldenYellow,
                              '&:hover': {
                                width: '100%',
                                transition: '.5s linear',
                              },
                            }}
                          />
                        </MenuItem>
                      )
                  )}
                </Menu>
              </Box>
            )}

          {/* display menu for pc */}
          {userDetailsInfo?.betauser &&
            supabaseUser &&
            userDetailsInfo?.hasactivelicence &&
            session && (
              <Box
                sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                id="navbar-page-links"
              >
                {pages().map(
                  (item: PagesObject | null, index: number) =>
                    item && (
                      <Link
                        to={item.link}
                        className="navbar-page-link"
                        key={index}
                        onClick={() => {
                          if (
                            featuresList &&
                            item.featureId &&
                            !featuresList[item.featureId]?.seen
                          ) {
                            dispatch(updateFeature(item.featureId));
                          }
                        }}
                        style={{
                          margin: '5px 5px',
                          padding: '5px',
                          color: theme.palette.primary.main,
                          background: 'transparent',
                          display: 'block',
                          textTransform: 'capitalize',
                          fontSize: '1rem',
                        }}
                      >
                        <FeatureComp
                          show={
                            featuresList
                              ? item.featureId
                                ? featuresList[item.featureId]?.seen
                                : true
                              : true
                          }
                        >
                          <Typography>{t(item.text)}</Typography>
                        </FeatureComp>
                        <Box
                          component="div"
                          sx={{
                            width:
                              location.pathname === item.link ? '100%' : '0%',
                            height: '3px',
                            transition: '.2s linear',
                            background: theme.palette.general.goldenYellow,
                            '&:hover': {
                              width: '100%',
                              transition: '.5s linear',
                            },
                          }}
                        />
                      </Link>
                    )
                )}
              </Box>
            )}
          {userDetailsInfo?.betauser &&
            supabaseUser &&
            userDetailsInfo?.hasactivelicence &&
            session && (
              <Box
                sx={{
                  display: 'inline-flex',
                  gridGap: '1em',
                }}
              >
                <Button
                  style={{
                    backgroundColor: theme.palette.general.goldenYellow,
                    textTransform: 'inherit',
                    minHeight: '40px',
                    color: theme.palette.primary.main,
                  }}
                  onClick={handleRequestReviewButtonClicked}
                >
                  {t('nav.requestReview')}
                </Button>
                <LanguageBtnComp />
                <MenuListComp
                  list={profileMenu(t).list}
                  menuView={profileMenu(t).menuView}
                  title={profileMenu(t).title}
                  type="iconButton"
                />
              </Box>
            )}
        </Toolbar>
        <RequestReviewPopup
          show={showRequestReviewPopup}
          setShow={setShowRequestReviewPopup}
          close={closeRequestReviewPopup}
        />
      </Container>
    </AppBar>
  );
};

export default MyNavbarComp;
