import {
  GlossaryData,
  GlossaryEntrie,
  getGlossaryEntries,
} from 'src/types/glossaryTypes.types';
import http from '../config/httpComman';
import { InsertToString } from 'src/utils/insertToString';

export default class glossaryService {
  static async searchGlossaryApi(data: {
    inputText: string;
    email: string;
    tags: string[];
  }) {
    return await http.post('glossary/searchtext/', {
      inputText: data.inputText,
      user: data.email,
      tags: data.tags,
    });
  }

  static async searchAIGlossaryApi(data: { word: string; email: string }) {
    return await http.post('glossary/ai/search/', {
      word: data.word,
      user: data.email,
    });
  }

  static async getGlossaryTagsApi() {
    return await http.get('glossary/tags');
  }

  static async deleteGlossaryApi(glossaryId: string) {
    return await http.delete('glossary/delete/' + glossaryId);
  }

  static async createNewGlossaryApi(data: GlossaryData) {
    return await http.post('glossary/newentry/', data);
  }

  static async getAllGlossaryEntries(data: getGlossaryEntries) {
    let url = InsertToString('glossary/all-paginated/', data);
    return await http.get(url);
  }

  static async updateGlossaryEntry(data: GlossaryEntrie) {
    return await http.put('glossary/update/', data);
  }

  static async deleteGlossaryEntry(id: string | number) {
    return await http.delete(`glossary/delete/${id}/`);
  }

  static async shareGlossaryEntitesApi(data: {
    access: 'private' | 'company';
  }) {
    return await http.post(`glossary/share/`, data);
  }

  static async getShareGlossaryValueApi() {
    return await http.get(`glossary/share/`);
  }

  static async getHardWordsApi(data: {
    text: string;
    with_explanations: boolean;
    use_cached_explanations: boolean;
  }) {
    return await http.post('glossary/ai/search/hard-words/', data);
  }
}
