import { useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import TextareaComp from 'src/coreUI/TextAreaComp/TextareaComp';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setRatingFeedback } from 'src/redux/feedback/actions';
import { RatingContext } from './RatingSnackbar';

type Props = {
  rating: number;
};

const RatingInput = ({ rating }: Props) => {
  const { t } = useTranslation('common');
  const dispatch: any = useDispatch();
  const { handleDismiss } = useContext(RatingContext);

  const [text, setText] = useState('');
  const handleSubmit = () => {
    localStorage.removeItem('ratingVal');
    dispatch(
      setRatingFeedback({ status: 'done', rating: `${rating}`, message: text })
    );
    handleDismiss();
  };
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <TextareaComp
          aria-label={t('rating_placeholder')}
          minRows={10}
          maxRows={10}
          placeholder={t('rating_placeholder')}
          style={{
            resize: 'none !important',
            width: '100% !important',
            height: '8em',
          }}
          slotStyle={{
            width: '100%',
          }}
          value={text}
          handleChange={(e) => setText(e.target.value)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        textAlign={'end'}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ p: '5px 30px' }}
          onClick={handleSubmit}
        >
          {t('submit')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RatingInput;
