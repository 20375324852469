import InputComponent from 'src/coreUI/inputComp/InputComponent';
import { isEmpty } from 'lodash';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import MaterialSelectComp from 'src/coreUI/materialSelect/MaterialSelectComp';
import { useTranslation } from 'react-i18next';

type Props = {
  reviewData: {
    length: string;
    target: string;
    email: string;
    terms: boolean;
  };
  handleChange: (e: any) => void;
};

const RequestReviewForm = ({ reviewData, handleChange }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('common');

  const targets = [
    {
      id: 'rew-1',
      value: 'Menschen mit Lernschwierigkeiten',
      text: t('requestReviewModal.targetGroup1'),
    },
    {
      id: 'rew-2',
      value: 'Menschen mit Deutsch als Fremdsprache',
      text: t('requestReviewModal.targetGroup2'),
    },
    {
      id: 'rew-3',
      value: 'Menschen mit Bildungsnachteilen',
      text: t('requestReviewModal.targetGroup3'),
    },
    {
      id: 'rew-4',
      value: 'Ältere Menschen',
      text: t('requestReviewModal.targetGroup4'),
    },
    {
      id: 'rew-5',
      value: 'Weiß ich noch nicht',
      text: t('requestReviewModal.targetGroup5'),
    },
  ];

  return (
    <Grid
      item
      container
      xs={12}
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <InputComponent
          label={t('requestReviewModal.lengthQuestion')}
          placeholder={t('requestReviewModal.lengthPlaceholder')}
          error={!isEmpty(reviewData?.length)}
          value={reviewData?.length}
          name={'length'}
          handleChange={handleChange}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <MaterialSelectComp
          menuItem={targets}
          handleChange={handleChange}
          value={reviewData.target}
          error={!isEmpty(reviewData.target)}
          label={t('requestReviewModal.targetQuestion')}
          name={'target'}
          formStyle={{ width: '100%', mt: 0, m: 0 }}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <InputComponent
          label={t('requestReviewModal.emailQuestion')}
          placeholder={t('requestReviewModal.emailPlaceholder')}
          error={!isEmpty(reviewData.email)}
          value={reviewData.email}
          name={'email'}
          handleChange={handleChange}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          id="agree_term"
          control={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fill: theme.palette.primary.main,
                },
              }}
            />
          }
          onChange={(e: React.SyntheticEvent | any) => {
            handleChange({
              target: {
                name: 'terms',
                value: e?.target.checked,
              },
            });
          }}
          value={reviewData.terms}
          label={
            <Typography
              variant="body2"
              sx={{ display: 'inline-block' }}
            >
              {t('requestReviewModal.termsLabel')}
            </Typography>
          }
          sx={{
            width: '100%',
            textAlign: 'left',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default RequestReviewForm;
