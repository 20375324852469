import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootreducers from './reducers';
import { resetAppSetting } from './appSetting/actions';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: rootreducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      sentry: sentryReduxEnhancer,
      serializableCheck: false,
      reduxReset: resetAppSetting,
    }),
});

// Create a persistor to persist the Redux store
export const persistor = persistStore(store);

export default store;
