import React, { useEffect, useRef } from 'react';
import { Box, Paper, Typography, Skeleton } from '@mui/material';
import { Message } from './types';
import { useSelector, shallowEqual } from 'react-redux';
import { ChatbotSelector } from 'src/redux/chatbot/selector';
import { TypeAnimation } from 'react-type-animation';

interface ChatMessagesProps {
  messages: Message[] | [];
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const loading = useSelector(
    (state: any) => ChatbotSelector(state).loading,
    shallowEqual
  );

  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Scroll to bottom when messages or loading state changes
  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: '#f8f9fa',
      }}
    >
      {messages.map((message) => (
        <Box
          key={message.id}
          sx={{
            display: 'flex',
            justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
            maxWidth: '100%',
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 2,
              maxWidth: '75%',
              backgroundColor:
                message.role === 'user' ? 'primary.main' : '#ffffff',
              color: message.role === 'user' ? '#ffffff' : 'text.primary',
              borderRadius:
                message.role === 'user'
                  ? '20px 20px 4px 20px'
                  : '20px 20px 20px 4px',
              boxShadow:
                message.role === 'user'
                  ? '0 2px 8px rgba(0,0,0,0.12)'
                  : '0 2px 8px rgba(0,0,0,0.06)',
            }}
          >
            {message.message === 'loading message' ? (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Skeleton
                    variant="text"
                    width={120}
                    height={20}
                  />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={20}
                  />
                  <Skeleton
                    variant="text"
                    width={160}
                    height={20}
                  />
                </Box>
                <Skeleton
                  variant="text"
                  width={60}
                  height={16}
                  sx={{ mt: 1, opacity: 0.8 }}
                />
              </>
            ) : message.role === 'user' || !message.new ? (
              <Typography
                variant="body1"
                sx={{
                  fontSize: '0.95rem',
                  lineHeight: 1.5,
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}
              >
                {message.message}
              </Typography>
            ) : (
              <TypeAnimation
                aria-label={message.message}
                sequence={[message.message]}
                speed={{ type: 'keyStrokeDelayInMs', value: 10 }}
                wrapper="p"
                cursor={false}
                repeat={0}
                style={{
                  fontSize: '0.95rem',
                  lineHeight: 1.5,
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                }}
              />
            )}
          </Paper>
        </Box>
      ))}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatMessages;
