import React, { useState } from 'react';
import {
  Box,
  Fab,
  Paper,
  IconButton,
  Typography,
  Collapse,
  useTheme,
  Tooltip,
  Divider,
} from '@mui/material';
import { MessageCircle, X, RefreshCw, ArrowLeft } from 'lucide-react';
import ChatbotOptions, { options } from './ChatbotOptions';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import { ChatbotState } from './types';
import { ReactComponent as BeeIcon } from '../../assets/bee-icon.svg';
import { ChatbotSelector } from 'src/redux/chatbot/selector';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { resetStateValues, startNewChat } from 'src/redux/chatbot/actions';
const Chatbot: React.FC = () => {
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const [state, setState] = useState<ChatbotState>({
    selectedOption: null,
    isOpen: false,
  });

  const messages = useSelector(
    (state: any) => ChatbotSelector(state).msgList,
    shallowEqual
  );

  const selectedRuleId = useSelector(
    (state: any) => ChatbotSelector(state).selectedRuleId,
    shallowEqual
  );

  const handleToggle = () => {
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleSelectOption = (id: string) => {
    dispatch(startNewChat(id));
  };

  const handleReset = () => {
    dispatch(
      resetStateValues({
        msgList: [],
        selectedRuleId: null,
      })
    );
  };

  return (
    <>
      <Fab
        onClick={handleToggle}
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 99999,
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          backgroundColor: theme.palette.general.white,
          '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.2s ease-in-out',
          },
        }}
      >
        <Tooltip title="Lexi Bee">
          <BeeIcon style={{ width: '2.5em', transform: 'rotate(-30deg)' }} />
        </Tooltip>
      </Fab>

      <Collapse
        in={state.isOpen}
        sx={{
          position: 'fixed',
          bottom: 90,
          right: 24,
          zIndex: 9999,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            width: 380,
            height: 500,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            borderRadius: 3,
            backgroundColor: theme.palette.general.white,
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
            zIndex: 1999,
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: theme.palette.general.white,
              color: theme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            }}
          >
            {messages.length === 0 && selectedRuleId ? (
              <IconButton
                size="small"
                onClick={handleReset}
                sx={{
                  color: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  },
                }}
              >
                <ArrowLeft size={20} />
              </IconButton>
            ) : (
              <MessageCircle size={22} />
            )}

            <Typography
              variant="h6"
              sx={{
                flexGrow: 1,
                fontSize: '1rem',
                fontWeight: 600,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedRuleId ? options[selectedRuleId] : 'Lexi Bee'}
            </Typography>
            <Box sx={{ display: 'flex' }}>
              {selectedRuleId && messages.length > 0 && (
                <Tooltip
                  title="Neuen Chat beginnen"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'zIndex',
                        enabled: true,
                        phase: 'write',
                        fn: ({ state }) => {
                          state.styles.popper.zIndex = '20000';
                        },
                      },
                    ],
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={handleReset}
                    sx={{
                      color: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                      },
                    }}
                  >
                    <RefreshCw size={18} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                size="small"
                onClick={handleToggle}
                sx={{
                  color: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,0.1)',
                  },
                }}
              >
                <X size={20} />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ background: '#ccc' }} />

          {!selectedRuleId ? (
            <ChatbotOptions onSelectOption={handleSelectOption} />
          ) : (
            <>
              <ChatMessages messages={messages} />
              <ChatInput />
            </>
          )}
        </Paper>
      </Collapse>
    </>
  );
};

export default Chatbot;
