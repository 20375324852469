import GetAppIcon from '@mui/icons-material/GetApp';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import themeConfig from '../../theme/themeConfig';
import React from 'react';
import LinkIcon from '@mui/icons-material/Link';

export const actionButton: (
  handleDownload?: (id: string) => void,
  item?: any,
  handleFeedback?: (id: string, feedback: 0 | 1 | 2) => void,
  handleInsertImage?: (id: string, imageId: string) => void
) => {
  action: any;
  label: string;
  Icon: any;
}[] = (handleDownload, item, handleFeedback, handleInsertImage) => [
  ...(handleFeedback
    ? [
        // ...(handleInsertImage
        //   ? [
        //       {
        //         action: () =>
        //           handleInsertImage(
        //             item.content_base64
        //               ? item.content_base64
        //               : `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/proxy/${item.uuid}`
        //           ),
        //         label: 'insert image',
        //         Icon: PlaylistAddIcon,
        //       },
        //     ]
        //   : []),
      ]
    : []),
  ...(handleDownload
    ? [
        {
          action: () => handleDownload(item.content_base64 || item.uuid),
          label: 'download',
          Icon: GetAppIcon,
        },
      ]
    : []),
];

export const menuListItem = (
  t: any,
  item: any,
  handleDelete?: (id: string) => void
) => {
  let list = [];
  // if (handleDelete) {
  //   list.push({
  //     id: 'image-upload-1',
  //     value: (
  //       <>
  //         <Button
  //           sx={{
  //             padding: '0',
  //             textTransform: 'lowercase',
  //             fontSize: '.9rem',
  //             color: themeConfig.palette.primary.main,
  //             '&:hover': {
  //               background: 'none',
  //             },
  //           }}
  //           onClick={() => handleDelete(item.uuid)}
  //           startIcon={
  //             <DeleteIcon
  //               sx={{
  //                 fontSize: '16px',
  //                 color: themeConfig.palette.primary.main,
  //               }}
  //             />
  //           }
  //         >
  //           {t('delete')}
  //         </Button>
  //       </>
  //     ),
  //   });
  // }

  if (item.license) {
    list.push({
      id: 'image-upload-2',
      value: (
        <>
          <Button
            sx={{
              padding: '0',
              textTransform: 'lowercase',
              fontSize: '.9rem',
              color: themeConfig.palette.primary.main,
              '&:hover': {
                background: 'none',
              },
            }}
            href={item.license?.link}
            target="_blank"
            rel="noreferrer"
            startIcon={
              <LinkIcon
                sx={{
                  fontSize: '16px',
                  color: themeConfig.palette.primary.main,
                }}
              />
            }
          >
            {t('imageSource')}
          </Button>
        </>
      ),
    });
  }
  return list;
};
