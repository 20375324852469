import { createSelector } from 'reselect';
import { VariantType } from 'notistack';

export interface HistorySettingTypes {
  filters?: {
    field: string;
    operation: string;
    value: string;
    actualValue?: string;
  };
  langLevel?: string;
  entriesNum?: string;
}
export interface Config {
  trigger: boolean;
  AlertMessage: { msg: string; variant: VariantType; options?: object };
  sidebarTab: number;
  textareaDimension: { width: number; height: number };
  historyTransSetting: HistorySettingTypes;
  selectedNodeKey: string | null;
}
interface RootState {
  appSetting: Config;
}

// This used to memorize the selector value
export const configSelector = createSelector(
  (state: RootState) => state.appSetting,
  (config: Config) => {
    return {
      trigger: config.trigger,
      AlertMessage: config.AlertMessage,
      sidebarTab: config.sidebarTab,
      textareaDimension: config.textareaDimension,
      historyTransSetting: config.historyTransSetting,
      selectedNodeKey: config.selectedNodeKey,
    };
  }
);
