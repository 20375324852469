import htmlDocx from 'html-docx-js/dist/html-docx';

const updatedHtmlString = async (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const imgElements = doc.querySelectorAll('img');
  let maxWidth = 0;

  await Promise.all(
    Array.from(imgElements).map(
      (img) =>
        new Promise<void>((resolve) => {
          const tempImg = new Image();
          tempImg.crossOrigin = 'anonymous';
          tempImg.src = img.src;

          tempImg.onload = async () => {
            const naturalWidth = tempImg.naturalWidth / 1.5;
            const naturalHeight = tempImg.naturalHeight / 1.5;

            if (img.src.startsWith('blob:')) {
              try {
                const base64 = await convertBlobUrlToBase64(img.src);
                if (
                  !base64.includes(
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAAAXNSR0IArs4c6QAACj'
                  )
                ) {
                  img.src = base64;
                } else {
                  img.src = '';
                }
              } catch (error) {
                // console.error('Blob to base64 failed:', error);
                img.src = '';
              }
            }

            // Apply resized dimensions
            img.setAttribute('width', naturalWidth.toString());
            img.setAttribute('height', naturalHeight.toString());

            // Update maxWidth (optional)
            if (naturalWidth > maxWidth) {
              maxWidth = naturalWidth;
            }

            resolve();
          };

          tempImg.onerror = () => {
            // console.warn('Image failed to load:', img.src);
            // Optionally remove or hide broken image tag
            img.src = '';
            resolve();
          };
        })
    )
  );

  // Merge all <tr>s into one table
  const allRows = Array.from(doc.querySelectorAll('table tr'));
  const newTable = doc.createElement('table');
  newTable.setAttribute('border', '1');
  newTable.style.width = '100%';

  allRows.forEach((row) => {
    newTable.appendChild(row);
  });

  doc.body.appendChild(newTable);

  // Return the updated HTML body content
  return doc.documentElement.outerHTML;
};

export const generateDocxFromHtml = async (
  htmlContent: string,
  filename: string = 'summ-ai'
) => {
  // console.log('htmlContent', htmlContent);
  // Define the header and footer for the Word document
  const header = `
   <html xmlns:o='urn:schemas-microsoft-com:office:office'
         xmlns:w='urn:schemas-microsoft-com:office:word'
         xmlns='http://www.w3.org/TR/REC-html40'>
   <head><meta charset='utf-8'><title>Document</title><style> 
   table {
      border: none;
      border-color:#fff;
      }

    table td, table th {
      border-color:#fff !important;
      }
        </style></head><body>`;
  const footer = `</body></html>`;

  // set fixed height for images
  htmlContent = await updatedHtmlString(htmlContent);
  // Combine the header, content, and footer
  const fullHtml = header + htmlContent + footer;

  // Create a Blob from the HTML string
  const blob = htmlDocx.asBlob(fullHtml, fullHtml);

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.docx`;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

const convertBlobUrlToBase64 = (blobUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = blobUrl;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);
      try {
        const base64 = canvas.toDataURL('image/png');
        resolve(base64);
      } catch (err) {
        reject(err);
      }
    };

    img.onerror = () => {
      reject(new Error('Failed to load blob image to convert'));
    };
  });
};
