import React, { useRef, useState } from 'react';
import { Form, Alert, Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import styles from './ForgotPassword.module.css';
import { useTranslation } from 'react-i18next';
import { passwordResetAction } from 'src/redux/supabase/actions';
import ResetPasswordForm from 'src/components/resetPasswordForm/ResetPasswordForm';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as EmailLock } from '../assets/reste-otp/email-lock.svg';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { useTheme } from '@mui/material';

type Props = {};
export default function ForgotPassword(props: Props) {
  const theme = useTheme();
  const { t } = useTranslation('common');
  const history = useNavigate();
  // ref to the form fields
  const emailRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const loading = useSelector(
    (state: any) => supabaseSelector(state).loading,
    shallowEqual
  );

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>,
    email: string
  ) {
    e.preventDefault();

    try {
      dispatch<any>(passwordResetAction(email.toLocaleLowerCase()));
      sessionStorage.setItem('email', email.toLocaleLowerCase());
      sessionStorage.setItem('type', 'password-reset');
      // Password reset email sent.
      setError('');
      setSuccess(
        `${t(
          'forgotPassword.passwordReset1'
        )} <b>${email.toLocaleLowerCase()}</b>.<br/><br/>
            ${t('forgotPassword.passwordReset2')}`
      );
      history('/confirm');
    } catch (error) {
      const code = (error as any).code;
      setSuccess('');

      switch (code) {
        case 'auth/invalid-email':
          setError(t('forgotPassword.invalidEmail'));
          break;
        case 'auth/user-not-found':
          setError(
            t('forgotPassword.emailDoesNotExist')?.replace(
              '{email}',
              email.toLocaleLowerCase()
            )
          );
          break;
        default:
          setError(t('forgotPassword.unexpectedError'));
          break;
      }
    }
  }

  return sessionStorage.getItem('type') === 'new_password' ? (
    <ResetPasswordForm />
  ) : (
    <Container>
      <Row style={{ marginTop: '5em' }}>
        <Col>
          <MarkEmailUnreadIcon
            sx={{ fontSize: '7em', color: theme.palette.primary.main }}
          />
          <h2
            className="pt-2 text-center"
            style={{
              marginBottom: '0',
              color: theme.palette.primary.main,
              fontWeight: 'bold',
              marginTop: '10px',
              fontSize: '1.5rem',
            }}
          >
            {t('forgotPassword.resetPassword')}
          </h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && (
            <Alert variant="success">
              <div dangerouslySetInnerHTML={{ __html: success }} />
            </Alert>
          )}
          {!success ? (
            <Form
              onSubmit={(e) =>
                handleSubmit(e, emailRef.current ? emailRef.current.value : '')
              }
            >
              <Form.Group controlId="email">
                <Form.Label
                  style={{
                    marginBottom: 20,
                  }}
                >
                  {t('forgotPassword.enterEmail')}
                </Form.Label>

                <div className={'text-center ' + styles['forgot-pwd-div']}>
                  <Form.Control
                    placeholder="Email"
                    id="email"
                    className={styles['forgot-pwd-input']}
                    type="text"
                    ref={emailRef}
                    required
                  />
                  <LoadingButton
                    loadingIndicator={t('translationbutton.loading')}
                    loading={loading}
                    variant="contained"
                    className={'w-100 ' + styles['forgot-pwd-send']}
                    type="submit"
                    sx={{
                      textTransform: 'capitalize',
                      color: theme.palette.secondary.main,
                      backgroundColor: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                      },
                      '&:disabled': {
                        color: theme.palette.secondary.main,
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                    disableElevation
                  >
                    {t('submit')}
                  </LoadingButton>
                </div>
              </Form.Group>
            </Form>
          ) : (
            <></>
          )}

          <div className="w-100 text-center mt-2">
            <Link
              to="/"
              className="linkStyle"
            >
              {t('toLogin')}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
