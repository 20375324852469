import http from '../config/httpComman';

export default class userService {
  static async getUserDetailsApi() {
    return await http.get('user/details/');
  }

  static async updateUserDetailsApi(data: {
    hasreadwhatsnew?: boolean;
    format_input_switch?: boolean;
    format_output_switch?: boolean;
    preferred_output_language_type?: 'easy' | 'plain';
  }) {
    return await http.put('user/details/', data);
  }

  static async getUserQuotaApi() {
    return await http.get('license/quota/');
  }

  static async createUserKeyApi(name: string) {
    return await http.post('user/keys/', { name });
  }

  static async getUserKeyApi() {
    return await http.get('user/keys/');
  }

  static async deleteUserKeyApi(prefix: string) {
    return await http.delete(`user/keys/${prefix}/`);
  }

  static async updateUserKeyApi(prefix: string, name: string) {
    return await http.put(`user/keys/${prefix}/`, { name });
  }

  static async userSignupApi(data: object) {
    return await http.post('user/signup/', data);
  }

  static async userReviewApi(data: object) {
    return await http.post('user/new/reviewrequest/', data);
  }
}
