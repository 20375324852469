import React from 'react';

import { AuthProvider } from '../../contexts/AuthContext';
import { styled, ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/themeConfig';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
// import SnackbarDownload from '../ImagesPage/SnackbarDownload';
import ReportComplete from '../ImagesPage/SnackbarDownload';
import RatingSnackbar from '../feedbackRating/RatingSnackbar';

type Props = {
  children: React.ReactNode;
};

const StyledSnackbarContainer = styled('div')(({ theme }) => ({
  '& .notistack-Snackbar': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const StyledMaterialDesignContentWarning = styled(MaterialDesignContent)(
  () => ({
    '&.notistack-MuiContent-warning': {
      backgroundColor: 'rgb(255, 244, 229)',
      color: 'rgb(102, 60, 0)',
      whiteSpace: 'pre-line !important',
      maxWidth: theme.breakpoints.up('sm') ? '50%' : '100%',
    },
  })
);

const StyledMaterialDesignContentDefault = styled(MaterialDesignContent)(
  () => ({
    '& svg': {
      fill: '#fff',
    },
  })
);

const WrapperIndexComp = ({ children }: Props) => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <StyledSnackbarContainer>
          <SnackbarProvider
            maxSnack={3}
            style={{
              whiteSpace: 'pre-line',
              position: 'relative',
            }}
            Components={{
              warning: StyledMaterialDesignContentWarning,
              default: StyledMaterialDesignContentDefault,
              reportComplete: ReportComplete,
              RatingSnackbar: RatingSnackbar,
            }}
          >
            {children}
          </SnackbarProvider>
        </StyledSnackbarContainer>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default WrapperIndexComp;
