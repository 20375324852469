import { useNavigate } from 'react-router-dom';
import { Nav, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './SideMenuLogoutItem.module.css';
import { useDispatch } from 'react-redux';
import { logoutAction } from 'src/redux/supabase/actions';
const SideMenuLogoutItem = () => {
  const history = useNavigate();
  const { t } = useTranslation('common');
  const dispatch: any = useDispatch();

  async function handleLogout(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    try {
      await dispatch(logoutAction());
      history('/');
    } catch {
      console.log('logout error');
    }
  }

  return (
    <Nav.Item>
      <Button
        className={styles['logout-item']}
        type="submit"
        onClick={handleLogout}
      >
        {t('logout')}
      </Button>
    </Nav.Item>
  );
};

export default SideMenuLogoutItem;
