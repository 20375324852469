import { Box, Typography, useTheme } from '@mui/material';
import imageSearchHint from '../../assets/image_placeholder.png';
import { LangWords } from './image.types';

type Props = {
  langWords: LangWords;
  light: boolean;
};

export default function SearchHintBox(props: Props) {
  const them = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: props.light ? them.palette.general.lightGray : '',
        borderRadius: '16px',
        display: 'flex',
        overflow: 'hidden',
        p: 3,
        pb: 5,
      }}
    >
      {/* Left side: Image */}
      <Box
        component="img"
        src={imageSearchHint} // Replace with your image URL
        alt="Sample Image"
        sx={{
          borderRadius: 2,
          width: '20%',
          objectFit: 'cover',
        }}
      />

      {/* Right side: Text */}
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'top',
        }}
      >
        <Typography
          sx={{
            pl: props.light ? 2 : 6,
            textAlign: 'left',
            whiteSpace: 'normal',
            maxWidth: '100%',
            color: props.light
              ? them.palette.primary.main
              : them.palette.general.white,
          }}
        >
          {props.langWords.imageSearchHint}
        </Typography>
      </Box>
    </Box>
  );
}
