import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import { Send } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { sendChatbotMsg } from 'src/redux/chatbot/actions';

interface ChatInputProps {}

const ChatInput: React.FC<ChatInputProps> = () => {
  const [message, setMessage] = useState('');
  const dispatch: any = useDispatch();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      dispatch(sendChatbotMsg(message));
      setMessage('');
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        p: 2,
        borderTop: 1,
        borderColor: 'divider',
        backgroundColor: '#ffffff',
        width: '100%',
      }}
    >
      <TextField
        fullWidth
        size="small"
        placeholder="Stellen Sie mir eine Frage..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '24px',
            backgroundColor: '#f8f9fa',
            '&:hover': {
              backgroundColor: '#f0f1f2',
            },
            '&.Mui-focused': {
              backgroundColor: '#ffffff',
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                type="submit"
                color="primary"
                disabled={!message.trim()}
                sx={{
                  '&:not(:disabled):hover': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                  },
                }}
              >
                <Send size={20} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ChatInput;
