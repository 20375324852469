import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import LoginForm from '../loginPage/LoginForm';
import LanguageSwitchBtn from '../languageSwitchComp/LanguageSwitchBtn';

import logo from '../../assets/logo_dark.svg';
import { useTranslation } from 'react-i18next';
import { clearLocalStorage } from '../utils/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../redux/user/actions';
import { getSessionAction, loginAction } from '../../redux/supabase/actions';
import { supabaseSelector } from '../../redux/supabase/selector';
import { UserSelector } from '../../redux/user/selector';
import LoadingHOC from 'src/coreUI/loadingHOC/LoadingHOC';
import { Alert, Container, Grid } from '@mui/material';
const Homepage = () => {
  const { t } = useTranslation('common');
  // states
  const history = useNavigate();
  const [error, setError] = useState('');

  const dispatch: any = useDispatch();
  const [showUpdateAppPopup, setShowUpdateAppPopup] = useState(false);
  const [loginVal, setLoginVal] = useState({
    email: '',
    password: '',
  });

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const userExist = useSelector(
    (state: any) => UserSelector(state).userExist,
    shallowEqual
  );

  const loading = useSelector(
    (state: any) => UserSelector(state).loading,
    shallowEqual
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setLoginVal({ ...loginVal, [name]: value });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setError('');

      await dispatch(loginAction(loginVal.email, loginVal.password));
      if (!supabaseUser && localStorage.getItem('signup_form')) return;
      if (userExist) {
        await dispatch(getUserDetails());
      }
      // if login successful then
      if (supabaseUser) {
        history('/dashboard');
      }
    } catch {
      setError(t('homePage.loginError'));
    }
  }

  const checkAppVersion = () => {
    // check if app needs to be updated
    const appVersionStr = localStorage.getItem('appversion');
    if (appVersionStr !== null) {
      const appVersion = +appVersionStr.split('.')[0];

      if (appVersion < 2) {
        setShowUpdateAppPopup(true);
      }
    }
  };

  useEffect(() => {
    checkAppVersion();
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('type');
  }, []);

  useEffect(() => {
    dispatch(getSessionAction());
    if (supabaseUser && !localStorage.getItem('signup_form') && userDetails) {
      history('/dashboard');
    }
  }, [supabaseUser, dispatch, history, userDetails]);

  return (
    <LoadingHOC
      loading={!userDetails && loading}
      size="46px"
    >
      <Container sx={{ pt: '2em' }}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'right',
          }}
        >
          <LanguageSwitchBtn />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <img
            className="pt-4"
            src={logo}
            alt="Logo"
          />
        </Grid>
        {error && <Alert title="danger">{error}</Alert>}
        <Grid
          item
          xs={12}
        >
          {!userDetails ? (
            <div className="mt-5">
              <LoginForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                loginVal={loginVal}
                // emailRef={emailRef}
                // passwordRef={passwordRef}
                // forgotPasswordRef={''}
                showUpdateAppPopup={showUpdateAppPopup}
                clearLocalStorage={clearLocalStorage}
                // setShowUpdateAppPopup={setShowUpdateAppPopup}
              />
              <hr />
              <div className="w-100 text-center mt-2">
                {t('homePage.noAccountYet')}{' '}
                <Link
                  to="/signup"
                  className="linkStyle"
                >
                  {t('homePage.toRegistration')}
                </Link>
              </div>
            </div>
          ) : (
            <div>{t('homePage.toDashboard')}</div>
          )}
        </Grid>
      </Container>
    </LoadingHOC>
  );
};

export default Homepage;
