import React from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setRatingFeedback } from 'src/redux/feedback/actions';

interface RatingNumbersProps {
  handleRatingSelect: (rating: number) => void; // New prop
}

const RatingNumbers: React.FC<RatingNumbersProps> = ({
  handleRatingSelect,
}) => {
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const { t } = useTranslation('common');
  const handleButtonClick = (rating: number) => {
    handleRatingSelect(rating); // Call the new prop function

    if (rating > 7) {
      return dispatch(
        setRatingFeedback({ status: 'done', rating: `${rating}`, message: '' })
      );
    } else {
      localStorage.setItem('ratingVal', rating.toString());
    }
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        color: theme.palette.primary.main,
      }}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: 'left',
            color: theme.palette.primary.main,
          }}
        >
          {t('recommendation_question')}
        </Typography>
      </Grid>
      {Array.from({ length: 10 }, (_, index) => index + 1).map((number) => (
        <Grid
          item
          key={number}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleButtonClick(number)}
            sx={{
              minWidth: 'auto',
              backgroundColor: 'transparent',
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disableElevation
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            {number}
          </Button>
        </Grid>
      ))}
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Typography
          variant="body2"
          color={theme.palette.primary.main}
        >
          {t('unlikely')}
        </Typography>
        <Typography
          variant="body2"
          color={theme.palette.primary.main}
        >
          {t('very_likely')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RatingNumbers;
