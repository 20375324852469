import { FixMe } from 'src/types/types';
import {
  DELETE_GLOSSARY_ENTRIES,
  GET_GLOSSARY_ENTRIES,
  GET_SHARE_GLOSSARY_VALUE,
  LOADING,
  SET_FILTER_VALUE,
  STOP_LOADING,
  TAGS_UPDATE,
  UPDATE_CURRENT_PAGE,
  UPDATE_GLOSSARY_ENTRIES,
  UPDATE_SHARE_GLOSSARY_VALUE,
} from './types';

const initialState: any = {
  glossaryEntriesList: [],
  tags: {},
  loading: false,
  count: 0,
  next: null,
  previous: null,
  currentPage: 1,
  entries: '10',
  filters: null,
  shareGlossary: 'private',
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_GLOSSARY_ENTRIES: {
      const currentTags: { [key: string]: string[] } = {};
      const updatedGlossaryList: { [key: string]: object } = {};
      payload.glossary_terms?.forEach((row: FixMe) => {
        currentTags[row.id] = row.tags ? row.tags?.split(',') : [];
        row.timestampcreated = new Date(row.timestampcreated);
        updatedGlossaryList[row.id] = row;
      });
      return {
        ...state,
        glossaryEntriesList: updatedGlossaryList,
        tags: currentTags,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
        currentPage: payload.currentPage,
        entries: `${payload.entries}`,
      };
    }
    case UPDATE_GLOSSARY_ENTRIES: {
      const updatedGlossaryList = { ...state.glossaryEntriesList };
      updatedGlossaryList[payload.id] = {
        ...updatedGlossaryList[payload.id],
        word: payload.word,
        explanation: payload.explanation,
        tags: payload.tags,
      };
      return {
        ...state,
        glossaryEntriesList: updatedGlossaryList,
      };
    }
    case DELETE_GLOSSARY_ENTRIES:
      const newGlossaryList = { ...state.glossaryEntriesList };
      delete newGlossaryList[payload];
      return {
        ...state,
        glossaryEntriesList: newGlossaryList,
      };
    case TAGS_UPDATE: {
      return {
        ...state,
        tags: payload,
      };
    }
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case SET_FILTER_VALUE:
      return {
        ...state,
        filters: payload,
      };
    case UPDATE_SHARE_GLOSSARY_VALUE:
      return {
        ...state,
        shareGlossary: payload.access,
      };
    case GET_SHARE_GLOSSARY_VALUE:
      return {
        ...state,
        shareGlossary: payload.detail.access,
      };
    default:
      return state;
  }
}
