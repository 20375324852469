import {
  TRANSLATE_LANG,
  LOADING_TRANS,
  STOP_LOADING_TRANS,
  NEW_LINE_SET,
  EMPTY_STATE_VALUE,
  GET_TRANS_CONFIG,
  SET_TRANS_CONFIG,
  UPDATE_TEXT_VALUE,
  UPDATE_SELECTED_OUTPUT_NODE,
  UPDATE_EMPTY_INPUT_VALUE,
  REGENERATE_TRANSLATION,
  UPDATE_JOB_STATUS,
  AUTO_LANG_DETECT,
  SET_IMAGE_PAGE_VISIBILITY,
  SET_TRANSLATION_PAGE_LOADING,
  ADD_IMAGE_TO_TABLE,
} from './types';

const initialState: any = {
  transjob: { jobId: '-1' },
  regeneratedJobs: [],
  loadingTrans: false,
  separatorStyle: 'hyphen',
  newLine: true,
  transLang: 'de',
  transConfig: null,
  selectedOutputNode: null,
  emptyInput: true,
  autoLangDetect: {
    text: null,
    value: null,
  },
  disableLikeDislike: {},
  showImagePage: false,
  translationPageLoading: false,
  selectedImg: {},
};

const newLines: { [key: string]: string } = {
  true: 'True',
  false: 'False',
  null: 'False',
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case TRANSLATE_LANG:
      const data = payload;
      const id = data.jobid;
      delete data.jobid;

      const transJob = {
        jobId: id,
        ...data,
      };
      return {
        ...state,
        transjob: transJob,
        regeneratedJobs: [transJob],
        disableLikeDislike: {
          [transJob.jobId]: false,
        },
      };
    case NEW_LINE_SET: {
      return {
        ...state,
        newLine: payload,
      };
    }
    case LOADING_TRANS:
      return {
        ...state,
        loadingTrans: true,
      };
    case STOP_LOADING_TRANS:
      return {
        ...state,
        loadingTrans: false,
      };
    case GET_TRANS_CONFIG:
      return {
        ...state,
        transConfig: {
          preferred_translation_language: {
            preferred_trans_language_easy:
              payload.preferred_trans_language_easy,
            preferred_trans_language_plain:
              payload.preferred_trans_language_plain,
          },
          preferred_new_lines: newLines[payload.preferred_new_lines],
          preferred_new_lines_easy: newLines[payload.preferred_new_lines_easy],
          preferred_new_lines_plain:
            newLines[payload.preferred_new_lines_plain],
        },
      };
    case SET_TRANS_CONFIG:
      return {
        ...state,
        transConfig: {
          preferred_translation_language: {
            preferred_trans_language_easy:
              payload.preferred_trans_language_easy,
            preferred_trans_language_plain:
              payload.preferred_trans_language_plain,
          },
          preferred_new_lines_easy: newLines[payload.preferred_new_lines_easy],
          preferred_new_lines_plain:
            newLines[payload.preferred_new_lines_plain],
        },
      };
    case EMPTY_STATE_VALUE:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_TEXT_VALUE:
      return {
        ...state,
        transjob: {
          ...state.transjob,
          simpleText: payload.output_text,
        },
      };
    case UPDATE_SELECTED_OUTPUT_NODE:
      return {
        ...state,
        selectedOutputNode: payload,
      };
    case UPDATE_EMPTY_INPUT_VALUE:
      return {
        ...state,
        emptyInput: payload,
      };
    case AUTO_LANG_DETECT:
      return {
        ...state,
        autoLangDetect: payload,
      };
    case REGENERATE_TRANSLATION:
      return {
        ...state,
        regeneratedJobs: [
          ...state.regeneratedJobs,
          {
            jobId: payload.jobid,
            inputText: state.transjob.inputText,
            ...payload,
          },
        ],
        transjob: {
          jobId: payload.jobid,
          inputText: state.transjob.inputText,
          ...payload,
        },
        disableLikeDislike: {
          ...state.disableLikeDislike,
          [payload.jobid]: false,
        },
      };
    case UPDATE_JOB_STATUS:
      return {
        ...state,
        disableLikeDislike: {
          ...state.disableLikeDislike,
          [payload]: true,
        },
      };
    case SET_IMAGE_PAGE_VISIBILITY:
      return {
        ...state,
        showImagePage: payload,
      };
    case SET_TRANSLATION_PAGE_LOADING:
      return {
        ...state,
        translationPageLoading: payload,
      };
    case ADD_IMAGE_TO_TABLE:
      return {
        ...state,
        selectedImg: { ...state.selectedImg, [payload.key]: payload.value },
      };
    default:
      return state;
  }
}
