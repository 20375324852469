import { isArray } from 'lodash';
import { GlossaryReducers } from './selector';
import {
  CREATE_GLOSSARY,
  SEARCH_GLOSSARY,
  LOADING,
  STOP_LOADING,
  SHOW_AI_SEARCH_UNAUTH_ALERT,
  AI_WORD_EXPLANATION,
  GET_GLOSSARY_TAGS,
  DELETE_GLOSSARY,
  EMPTY_STATE_VALUE,
  SELECTED_TAGS_LIST,
  OWN_EXPLANATIONS,
  SHOW_GLOSSARY_COPY_RESTRICTION_ALERT,
  DIFFICULT_WORDS_TO_SHOW,
  DIFFICULT_WORDS,
  UPDATE_GLOSSARY_ITEM,
  STOP_LOADING_AI,
  LOADING_AI,
  GET_HARD_WORDS,
} from './types';

const initialState: GlossaryReducers = {
  glossaryItems: null,
  searchInput: null,
  loading: false,
  loadingAI: false,
  unAuthAlert: false,
  AIWordExplanation: null,
  hardWords: null,
  glossaryTags: null,
  highLightWord: {},
  selectedTagsList: [],
  difficultWords: null,
  difficultWordsToShow: null,
  otherWordExplanation: null,
  hasCopyRestriction: null,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: {} | any }
) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_GLOSSARY:
      return {
        ...state,
        glossaryItems: {
          difficultWords: {
            ...(state.glossaryItems?.difficultWords ?? {}),
            [payload.id]: payload,
          },
        },
        highLightWord: {
          ...state.highLightWord,
          [payload.word?.replace('"', '')?.trim()]: 1,
        },
      };
    case SEARCH_GLOSSARY:
      let highLight = {};
      let glossaryItemUpdate = { difficultWords: {} };
      payload.resDtails.difficultWords?.forEach(
        (item: { original_words: string; id: string }) => {
          let splitWords = item.original_words?.split(',');
          splitWords?.forEach((word: string) => {
            highLight = {
              ...highLight,
              [word?.replace('"', '')?.trim()]: 1,
            };
          });

          glossaryItemUpdate.difficultWords = {
            ...glossaryItemUpdate.difficultWords,
            [item.id]: item,
          };
        }
      );

      // check if it is empty then clear the object
      return {
        ...state,
        glossaryItems: glossaryItemUpdate,
        highLightWord: { ...state.highLightWord, ...highLight },
        searchInput: payload.searchField,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case LOADING_AI:
      return {
        ...state,
        loadingAI: true,
      };
    case STOP_LOADING_AI:
      return {
        ...state,
        loadingAI: false,
      };
    case EMPTY_STATE_VALUE: {
      return {
        ...state,
        ...payload,
      };
    }
    case SHOW_AI_SEARCH_UNAUTH_ALERT:
      return {
        ...state,
        unAuthAlert: payload,
      };
    case AI_WORD_EXPLANATION:
      let highLightAI = {};
      payload?.forEach((item: { word: string }) => {
        highLightAI = {
          ...highLightAI,
          [item.word?.replace('"', '')?.trim()]: 1,
        };
      });
      return {
        ...state,
        AIWordExplanation: payload,
        highLightWord: { ...highLightAI, ...state.highLightWord },
      };
    case GET_GLOSSARY_TAGS:
      return {
        ...state,
        glossaryTags: payload,
      };
    case DELETE_GLOSSARY: {
      let newGlossaryItems: any = { ...state.glossaryItems?.difficultWords };
      delete newGlossaryItems[payload];

      let highLight = {};
      Object.values(newGlossaryItems)?.forEach((item: any) => {
        highLight = {
          ...highLight,
          [item.word?.replace('"', '')?.trim()]: 1,
        };
      });

      let highLightAI = {};
      state.AIWordExplanation?.forEach((item: { word: string }) => {
        highLightAI = {
          ...highLightAI,
          [item.word?.replace('"', '')?.trim()]: 1,
        };
      });

      return {
        ...state,
        glossaryItems: { difficultWords: newGlossaryItems },
        highLightWord: { ...highLightAI, ...highLight },
      };
    }
    case SELECTED_TAGS_LIST:
      return {
        ...state,
        selectedTagsList: payload,
      };
    case OWN_EXPLANATIONS:
      return {
        ...state,
        otherWordExplanation: payload,
      };
    case SHOW_GLOSSARY_COPY_RESTRICTION_ALERT:
      return {
        ...state,
        hasCopyRestriction: payload,
      };
    case DIFFICULT_WORDS_TO_SHOW:
      return {
        ...state,
        difficultWordsToShow: payload,
      };
    case DIFFICULT_WORDS:
      return {
        ...state,
        difficultWords: payload,
      };
    case UPDATE_GLOSSARY_ITEM:
      return {
        ...state,
        glossaryItems: {
          difficultWords: {
            ...state?.glossaryItems?.difficultWords,
            [payload.id]: {
              ...state?.glossaryItems?.difficultWords[payload?.id],
              ...payload,
            },
          },
        },
      };
    case GET_HARD_WORDS: {
      let hardWords: {
        word: string;
        explanation: string | null;
        copyRestriction: number;
      }[] = [];
      let highLightWord = {};

      // check if hard word without explanation
      if (isArray(payload)) {
        hardWords = payload.map((item: any) => {
          highLightWord = {
            ...highLightWord,
            [item?.replace('"', '')?.trim()]: 1,
          };
          return {
            word: item,
            explanation: null,
            copyRestriction: 0,
          };
        });
      } else if (!isArray(payload)) {
        // check if hard word with explanation
        for (let key in payload) {
          hardWords.push({
            word: key,
            explanation: payload[key],
            copyRestriction: 0,
          });
          highLightWord = {
            ...highLightWord,
            [key?.replace('"', '')?.trim()]: 1,
          };
        }
      }

      return {
        ...state,
        hardWords,
        highLightWord: {
          ...state.highLightWord,
          ...highLightWord,
        },
      };
    }
    default:
      return state;
  }
}
