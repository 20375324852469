import { SnackbarKey } from 'notistack';
import { createSelector } from 'reselect';

export interface FeedbackReducer {
  loading: boolean;
  feedback: any;
  ratingMsg: string;
  showRating: boolean;
  ratingVal: number;
  ratingSnackbarKey: null | SnackbarKey;
  ratingStep: number;
}
interface RootState {
  feedback: FeedbackReducer;
}

// This used to memorize the selector value
export const FeedbackSelector = createSelector(
  (state: RootState) => state.feedback,
  (config: FeedbackReducer) => {
    return {
      loading: config.loading,
      feedback: config.feedback,
      ratingMsg: config.ratingMsg,
      showRating: config.showRating,
      ratingVal: config.ratingVal,
      ratingSnackbarKey: config.ratingSnackbarKey,
      ratingStep: config.ratingStep,
    };
  }
);
