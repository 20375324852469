import http from '../config/httpComman';

export default class chatbotService {
  static async getHistoryAPi() {
    return await http.get('/chat/bot/');
  }

  static async startNewChatApi(rule: string) {
    return await http.post('/chat/bot/', { rule });
  }

  static async sendChatMsgApi(message: string) {
    return await http.put('/chat/bot/', { message });
  }
}
