import http from '../config/httpComman';
import { FeedbackFormInput } from '../types/feedback.types';

export default class feedbackService {
  static async createFeedback(data: FeedbackFormInput) {
    return await http.post('user/feedback/', data);
  }

  static async getRatingFeedbackApi() {
    return await http.get('app/feedback/');
  }

  static async setRatingFeedbackApi(data: {
    status?: 'done' | 'canceled';
    rating?: string;
    message?: string;
  }) {
    return await http.post('app/feedback/', data);
  }
}
