import { createSelector } from 'reselect';
import { Message } from 'src/components/Chatbot/types';

export interface ChatbotReducer {
  loading: boolean;
  msgList: [] | Message[];
  newChatId: null | boolean;
  selectedRuleId: null | string;
}
interface RootState {
  chatbot: ChatbotReducer;
}

// This used to memorize the selector value
export const ChatbotSelector = createSelector(
  (state: RootState) => state.chatbot,
  (config: ChatbotReducer) => {
    return {
      loading: config.loading,
      msgList: config.msgList,
      newChatId: config.newChatId,
      selectedRuleId: config.selectedRuleId,
    };
  }
);
