import { forwardRef, useCallback, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RatingStepper from './RatingStepper';
import { shallowEqual, useSelector } from 'react-redux';
import { FeedbackSelector } from 'src/redux/feedback/selector';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

declare module 'notistack' {
  interface VariantOverrides {
    RatingSnackbar: true;
  }
}

const useStyles = makeStyles(() => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
  },
  typography: {
    color: '#000',
  },
  actionRoot: {
    // padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    backgroundColor: '#fff',
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface RatingSnackbarProps extends CustomContentProps {
  allowDownload?: boolean;
}
export const RatingContext = React.createContext<any>({});
const RatingSnackbar = forwardRef<HTMLDivElement, RatingSnackbarProps>(
  ({ id, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useTranslation('common');
    const ratingMsg = useSelector(
      (state: any) => FeedbackSelector(state).ratingMsg,
      shallowEqual
    );

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    useEffect(() => {
      localStorage.setItem('ratingStep', '1');
    }, []);

    return (
      <RatingContext.Provider value={{ handleDismiss }}>
        <SnackbarContent
          ref={ref}
          className={classes.root}
        >
          <Card className={classes.card}>
            <CardActions
              sx={{ pb: '0 !important' }}
              classes={{ root: classes.actionRoot }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: '1.3rem',
                  color: theme.palette.primary.main,
                }}
              >
                {t(ratingMsg)}
              </Typography>
              <div className={classes.icons}>
                <IconButton
                  size="small"
                  className={classes.expand}
                  onClick={handleDismiss}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </CardActions>
            <Paper
              className={classes.paper}
              sx={{ p: '0 !important', width: '33em' }}
            >
              <RatingStepper />
            </Paper>
          </Card>
        </SnackbarContent>
      </RatingContext.Provider>
    );
  }
);

RatingSnackbar.displayName = 'RatingSnackbar';

export default RatingSnackbar;
