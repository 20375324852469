export const extractTextFromHTML = (html: string) => {
  // Create a temporary DOM element to parse the HTML
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Extract the text content from the element
  const textContent = tempElement.textContent || tempElement.innerText || '';

  // Clean up extra spaces and newlines
  return textContent;
};
