import { createSelector } from 'reselect';
import { ImageUploadItem } from 'src/types/imageUpload.types';

export interface AddImageReducer {
  generationLoading: boolean;
  searchLoading: boolean;
  imageList:
    | null
    | {
        uuid: string;
        similarity: number;
        feedback: 0 | 1 | 2;
        content_base64?: string;
        license?: any;
      }[];
  uploadedImages: null | {
    ownerList: null | { [key: string]: ImageUploadItem };
    otherList: null | { [key: string]: ImageUploadItem };
  };
  uploadedImageList: {
    [key: string]: { msg: string; status: 'error' | 'success'; id: string };
  };
  tempImageList: { [key: string]: string };
  heightlight: boolean;
  deletedImgId: null | string;
  generatedImages:
    | null
    | [
        {
          id: string;
          base64: string;
        }
      ];
  generatedImagesHighlight: boolean;
  imageSearchText: string;
  imageGenerationText: string;
  allImageResult: any;
  tableNodesWithImages: [string];
  hiddenTables: [string];
  lastImageGenerationJobId: number;
  imageMode: boolean;
}

interface RootState {
  addImage: AddImageReducer;
}

// This used to memorize the selector value
export const AddImageSelector = createSelector(
  (state: RootState) => state.addImage,
  (config: AddImageReducer) => {
    return {
      searchLoading: config.searchLoading,
      imageList: config.imageList,
      uploadedImages: config.uploadedImages,
      allImageResult: config.allImageResult,
      uploadedImageList: config.uploadedImageList,
      tempImageList: config.tempImageList,
      heightlight: config.heightlight,
      deletedImgId: config.deletedImgId,
      generationLoading: config.generationLoading,
      generatedImages: config.generatedImages,
      lastImageGenerationJobId: config.lastImageGenerationJobId,
      generatedImagesHighlight: config.generatedImagesHighlight,
      imageSearchText: config.imageSearchText,
      imageGenerationText: config.imageGenerationText,
      tableNodesWithImages: config.tableNodesWithImages,
      hiddenTables: config.hiddenTables,
      imageMode: config.imageMode,
    };
  }
);
