import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ChatOption } from './types';

interface ChatbotOptionsProps {
  onSelectOption: (option: string) => void;
}

export const options: ChatOption = {
  '1': 'DIN SPEC Empfehlungen',
  '2': 'Regelbuch Uni Hildesheim',
  '3': 'NLS-Regelwerk',
};

const ChatbotOptions: React.FC<ChatbotOptionsProps> = ({ onSelectOption }) => {
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: '#f8f9fa',
        height: '100%',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mb: 3,
          fontSize: '1rem',
          fontWeight: 600,
          textAlign: 'center',
          color: 'text.primary',
        }}
      >
        Zu welchem Regelwerk haben Sie eine Frage?
      </Typography>
      {Object.keys(options).map((id) => {
        return (
          <Button
            key={options[id]}
            variant="outlined"
            fullWidth
            sx={{
              mb: 2,
              p: 2.5,
              borderRadius: 2,
              borderColor: 'divider',
              backgroundColor: '#ffffff',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 2,
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#ffffff',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
              },
            }}
            onClick={() => onSelectOption(id)}
          >
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                  mb: 0.5,
                }}
              >
                {options[id]}
              </Typography>
            </Box>
          </Button>
        );
      })}
    </Box>
  );
};

export default ChatbotOptions;
