import {
  LOADING,
  STOP_LOADING,
  GET_HISTORY_CHAT,
  START_NEW_CHAT,
  SEND_CHAT_MSG,
  RESET_STATE_VALUE,
} from './types';

const initialState: any = {
  msgList: [],
  selectedRuleId: null,
  newChatId: null,
  loading: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_HISTORY_CHAT:
      return {
        ...state,
        msgList: payload.history,
        selectedRuleId: payload.datasource_id,
      };
    case START_NEW_CHAT:
      return {
        ...state,
        newChatId: payload.chatId,
        selectedRuleId: payload.ruleId,
      };
    case SEND_CHAT_MSG:
      let messageList = state.msgList;
      if (
        payload.role === 'assistant' &&
        payload.message !== 'loading message'
      ) {
        messageList.pop();
      }
      return {
        ...state,
        msgList: [...messageList, payload],
      };
    case RESET_STATE_VALUE:
      return {
        ...state,
        ...payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
