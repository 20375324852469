import imageUploadService from 'src/services/imageUpload.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  DELETE_IMAGE,
  DELETE_IMAGE_SET,
  FEEDBACK_IMAGE,
  GENERATED_IMAGE_RECEIVED,
  GENERATION_LOADING,
  IMAGE_ADDED_TO_TABLE,
  IMAGE_REMOVED_FROM_TABLE,
  RESET_IMAGE_UPLOAD_STATE,
  RESET_TABLE_IMAGES,
  SEARCH_LOADING,
  SEARCH_UPLOADED_IMAGE,
  SEARCHED_IMAGES_RECEIVED,
  SET_ALL_IMAGE_SEARCH_RESULT,
  SET_HIDDEN_TABLE,
  SET_IMAGE_GENERATION_TEXT,
  SET_IMAGE_MODE,
  SET_IMAGE_SEARCH_TEXT,
  SET_UPLOAD_IMAGE_LIST,
  STOP_GENERATION_LOADING,
  STOP_SEARCH_LOADING,
  UPLOAD_IMAGE,
  UPLOAD_LIST_IMAGE,
} from './types';
import { isEmpty } from 'lodash';
import Sentry from '../../config/sentryConfig';
import { outputEditor } from 'src/components/TextareaField/TextareaField';
import { updateGalleryState } from '../myGallery/actions';
import { UploadImageReducer } from 'sharedPackage/src/components/imageSearchComp';
import { t } from 'i18next';
import { AddImageSelector } from './selector';
import { updateSelectedOutputNode } from '../translation/actions';
import { TranslateSelector } from '../translation/selector';
import { getUserDetails } from '../user/actions';
import {
  getNodeContentAsync,
  resetHighlight,
  updateTableColumn,
} from 'src/components/TextareaField/helper';

export const addImageUpload =
  (data: File[], uploadedFrom: 'translation' | 'gallery' = 'translation') =>
  async (dispatch: any, getState: any) => {
    let count = 0;
    // delete the file from the list if it was already uploaded before
    dispatch(updateFileList([...data]));

    // Make the req to upload the file
    const sentRequest = async (file: File) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const res = await imageUploadService.uploadImageApi(formData);
        dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            status: 'success',
            id: file.name,
            msg: t('successUpload', { ns: 'easy_language' }),
          },
        });
        if (uploadedFrom === 'gallery') {
          dispatch(updateGalleryState(res.data.data.uuid));
        }
      } catch (e: any) {
        let msg = e?.response?.data?.detail ?? 'errorUpload';
        if (
          msg === 'The user has already mapping to the image in the database'
        ) {
          msg = 'alreadyUploaded';
        } else if (
          msg === 'Images can only have .jpg, .jpeg or .png extension'
        ) {
          msg = 'wrongExtension';
        }
        Sentry.captureMessage(`image upload Error: ${e}`, 'error');
        return dispatch({
          type: UPLOAD_IMAGE,
          payload: {
            msg,
            status: 'error',
            id: file.name,
          },
        });
      } finally {
        if (count < data.length - 1) {
          count += 1;
          sentRequest(data[count]);
        }
      }
    };
    sentRequest(data[count]);
  };

// todo will update this later with the correct api to fetch all the images
// export const getAllUploadedImages = () => async (dispatch: any) => {
//   try {
//     const res = await imageUploadService.getUploadedImageApi();
//     dispatch({
//       type: GET_ALL_UPLOADED_IMAGE,
//       payload: res.data,
//     });
//   } catch (e: any) {
//     return appMessageHandling(
//       dispatch,
//       e.detail || 'error.get_uploaded_image_error',
//       'error'
//     );
//   }
// };

export const feedbackImageUpload =
  (id: string, feedback: 0 | 1 | 2, name: number, isOwner: boolean) =>
  async (dispatch: any) => {
    try {
      const res = await imageUploadService.feedbackImageApi(id, feedback);
      dispatch({
        type: FEEDBACK_IMAGE,
        payload: { ...res.data, name, isOwner },
      });
    } catch (e: any) {
      Sentry.captureMessage(`image upload feedback Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };
export const deleteUploadedImage =
  (id: string, name: number, isOwner: boolean) => async (dispatch: any) => {
    try {
      dispatch(deleteImageSet(id));
      await imageUploadService.deleteUploadedImageApi(id);
      dispatch({
        type: DELETE_IMAGE,
        payload: { id, name, isOwner },
      });
      dispatch(deleteImageSet(null));
    } catch (e: any) {
      Sentry.captureMessage(`image upload delete Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e.detail || 'error.general_error',
        'error'
      );
    }
  };

export const downloadUploadedImage =
  (id: string) => (dispatch: any, getState: any) => {
    const userEmail = getState().user.userDetails.email;
    const link = document.createElement('a');
    link.href = `${process.env.REACT_APP_DJANGO_BACKEND_DOMAIN}images/download/${id}/${userEmail}`;
    link.setAttribute('download', id);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

export const showMoreImageSearchResults =
  () => async (dispatch: any, getState: any) => {
    const state = getState();
    const loading = AddImageSelector(state).searchLoading;

    if (loading) {
      return;
    }

    let allImageResults = AddImageSelector(state).allImageResult;
    if (!allImageResults) {
      return;
    }

    const currentImageResults = AddImageSelector(state).imageList;

    let count = currentImageResults?.length ?? 0;
    count += 3;

    if (count > allImageResults?.length) {
      count = allImageResults?.length;
    }

    if (count > (currentImageResults?.length ?? 0)) {
      let newImageResults = structuredClone(allImageResults).slice(0, count);

      dispatch({
        type: SEARCHED_IMAGES_RECEIVED,
        payload: { list: newImageResults ?? [] },
      });
    }
  };

export const emptyAllImages = (dispatch: any) => {
  dispatch({
    type: GENERATED_IMAGE_RECEIVED,
    payload: {
      list: null,
      replace: true,
    },
  });

  dispatch({
    type: SEARCH_UPLOADED_IMAGE,
    payload: { list: null },
  });

  dispatch({
    type: SEARCHED_IMAGES_RECEIVED,
    payload: { list: null },
  });

  dispatch({
    type: SET_ALL_IMAGE_SEARCH_RESULT,
    payload: { list: null },
  });

  dispatch(setImageSearchText(''));
  dispatch(setImageGenerationText(''));
};

function emptyImageSearchLists(dispatch: any) {
  dispatch({
    type: SEARCH_UPLOADED_IMAGE,
    payload: { list: [] },
  });

  dispatch({
    type: SEARCHED_IMAGES_RECEIVED,
    payload: { list: [] },
  });

  dispatch({
    type: SET_ALL_IMAGE_SEARCH_RESULT,
    payload: { list: [] },
  });
}

export const searchUploadedImages =
  (extract_keywords: boolean = false, query: string = '') =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const selectedNode = TranslateSelector(state).selectedOutputNode;

    let value = AddImageSelector(state).imageSearchText;
    if (query && query !== '') {
      value = query;
    }
    let canceled = false;

    if (!isEmpty(value)) {
      try {
        dispatch(searchLoading());
        let res: any = { data: null };
        if (!isEmpty(value)) {
          res = await imageUploadService.searchUploadedImageApi(
            value,
            extract_keywords
          );
        }

        canceled =
          selectedNode !== TranslateSelector(getState()).selectedOutputNode;
        if (canceled) {
          return;
        }

        let data = res?.data?.data;

        let flatData = [];
        if (data && data.length > 0) {
          data.forEach((d: any) =>
            d.images?.forEach((i: any) => (i.license = d.license))
          );
          flatData = data
            .flatMap((s: any) => s?.images)
            .sort((a: any, b: any) => b.similarity - a.similarity);
        }

        dispatch({
          type: SEARCH_UPLOADED_IMAGE,
          payload: { list: data ?? [] },
        });

        dispatch({
          type: SET_ALL_IMAGE_SEARCH_RESULT,
          payload: { list: flatData ?? [] },
        });

        if (flatData && flatData.length > 0) {
          flatData = structuredClone(flatData);
          flatData = flatData.slice(0, 3);
        }

        dispatch({
          type: SEARCHED_IMAGES_RECEIVED,
          payload: { list: flatData ?? [] },
        });

        let querySummary = res?.data?.querySummary;
        if (querySummary && querySummary.length > 0) {
          dispatch(setImageSearchText(querySummary));
        }
      } catch (e: any) {
        Sentry.captureMessage(`image upload search Error: ${e}`, 'error');
        emptyImageSearchLists(dispatch);
        if (e?.response?.status == 467) {
          return appMessageHandling(
            dispatch,
            'error.not_enough_quota',
            'error'
          );
        } else {
          return appMessageHandling(
            dispatch,
            e?.response?.data?.detail ?? 'error.general_error',
            'error'
          );
        }
      } finally {
        if (!canceled) {
          dispatch(stopSearchLoading());
          dispatch(getUserDetails());
        }
      }
    } else if (isEmpty(value)) {
      resetHighlight(outputEditor);
      // return dispatch(emptyStateValueImageUpload({ imageList: null }));
    }
  };

function reset_generated_images(dispatch: any) {
  dispatch({
    type: GENERATED_IMAGE_RECEIVED,
    payload: { list: [] },
  });
}

export const generateImages =
  (replace = true, createPrompt = false) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    const inputPrompt = AddImageSelector(state).imageGenerationText;
    const selectedNodeId = TranslateSelector(state).selectedOutputNode;
    const inputText = await getNodeContentAsync(selectedNodeId);
    const jobId = TranslateSelector(state).transjob?.jobId;
    const lastGenerationId = AddImageSelector(state).lastImageGenerationJobId;

    let canceled = false;

    if (!isEmpty(inputText)) {
      try {
        dispatch(generationLoading());
        const params = {
          inputText: inputText,
          inputPrompt: inputPrompt,
          createPrompt: createPrompt,
          translationJobId: jobId ?? undefined,
          parentGenerationJobId: lastGenerationId ?? undefined,
        };

        let res = await imageUploadService.generateImagesApi(params);

        canceled =
          selectedNodeId !== TranslateSelector(getState()).selectedOutputNode;
        if (canceled) {
          return;
        }

        dispatch({
          type: GENERATED_IMAGE_RECEIVED,
          payload: {
            list: res?.data?.images ?? [],
            replace: replace,
            jobId: res?.data?.generation_job_id ?? -1,
          },
        });

        if (res?.data?.user_prompt) {
          dispatch(setImageGenerationText(res?.data?.user_prompt));
        }
      } catch (e: any) {
        Sentry.captureMessage(`Image Generation Error: ${e}`, 'error');
        reset_generated_images(dispatch);
        if (e?.response?.status == 467) {
          return appMessageHandling(
            dispatch,
            'error.not_enough_quota',
            'error'
          );
        } else {
          return appMessageHandling(
            dispatch,
            e?.response?.data?.detail ?? 'error.general_error',
            'error'
          );
        }
      } finally {
        if (!canceled) {
          dispatch(stopGenerationLoading());
          dispatch(getUserDetails());
        }
      }
    } else {
      resetHighlight(outputEditor);
    }
  };

export const selectImagePlace =
  (value: string, nodeKey: string) => async (dispatch: any, getState: any) => {
    if (nodeKey) {
      dispatch(updateSelectedOutputNode(nodeKey));
    }

    // dispatch(setImageSearchText(value))
    dispatch(searchUploadedImages(true, value));

    emptyAllImages(dispatch);

    dispatch(setImageGenerationText(value));
    dispatch(generateImages(true, true));
  };

export const setImageSearchText =
  (value: string) => async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_IMAGE_SEARCH_TEXT,
      payload: value,
    });
  };

export const setImageGenerationText =
  (value: string) => async (dispatch: any, getState: any) => {
    dispatch({
      type: SET_IMAGE_GENERATION_TEXT,
      payload: value,
    });
  };

export const updateFileList = (data: File[]) => (dispatch: any) => {
  data?.forEach((item: File) => {
    dispatch({
      type: UPLOAD_LIST_IMAGE,
      payload: item.name,
    });
  });
};

export const searchLoading = () => (dispatch: any) => {
  return dispatch({
    type: SEARCH_LOADING,
  });
};

export const generationLoading = () => (dispatch: any) => {
  return dispatch({
    type: GENERATION_LOADING,
  });
};

export const stopSearchLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_SEARCH_LOADING,
  });
};

export const stopGenerationLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_GENERATION_LOADING,
  });
};

export const emptyStateValueImageUpload =
  (value: UploadImageReducer) => (dispatch: any) => {
    return dispatch({
      type: RESET_IMAGE_UPLOAD_STATE,
      payload: value,
    });
  };

export const setImageUploadList =
  (value: { [key: string]: string }) => (dispatch: any) => {
    return dispatch({
      type: SET_UPLOAD_IMAGE_LIST,
      payload: value,
    });
  };

export const deleteImageSet = (id: string | null) => (dispatch: any) => {
  return dispatch({
    type: DELETE_IMAGE_SET,
    payload: id,
  });
};

export const imageAddedToTable = (id: string | null) => (dispatch: any) => {
  return dispatch({
    type: IMAGE_ADDED_TO_TABLE,
    payload: id,
  });
};

export const imageRemovedFromTable = (id: string | null) => (dispatch: any) => {
  return dispatch({
    type: IMAGE_REMOVED_FROM_TABLE,
    payload: id,
  });
};

export const resetTableImages = () => (dispatch: any) => {
  return dispatch({
    type: RESET_TABLE_IMAGES,
    payload: '',
  });
};

export const setHiddenTable = (dispatch: any, tableKeys: any) => {
  return dispatch({
    type: SET_HIDDEN_TABLE,
    payload: tableKeys,
  });
};
export const insertImageToTable =
  (content: string, imageId?: string) => (dispatch: any, getState: any) => {
    const state = getState();

    const selectedNode = state.translation.selectedOutputNode;
    if (!selectedNode) {
      return;
    }

    updateTableColumn(outputEditor, content, imageId, selectedNode, dispatch);

    if (content.includes('http')) {
      sendFeedbackSearchedImage(content, getState);
    } else {
      sendFeedbackGeneratedImage(content, getState);
    }
  };

function sendFeedbackSearchedImage(content: string, getState: any) {}

export const sendFeedbackGeneratedImage = (content: string, getState: any) => {
  const state = getState();
  const images = state.addImage.generatedImages;
  const image = images.find((img: any) => img.base64 === content);
  if (image) {
    const id = image.id;

    imageUploadService.sendFeedback(id, true);
  }
};

export const setImageMode = (dispatch: any, mode: boolean) => {
  dispatch({
    type: SET_IMAGE_MODE,
    payload: mode,
  });
};
