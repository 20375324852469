import ImageUploadCompWrapper from './ImageSearchCompWrapper';
import Box from '@mui/material/Box';
import ImageGenerationWrapper from './ImageGenerationCompWrapper';
import ImageSectionPanel from './ImageSectionPanel';
import { useTranslation } from 'react-i18next';
import {
  generateImages,
  showMoreImageSearchResults,
} from '../../redux/addImage/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AddImageSelector } from '../../redux/addImage/selector';

type Props = {};

const ImageSelectionComp = (props: Props) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const [showMoreSearch, setShowMoreSearch] = useState(false);

  const imageList = useSelector(
    (state: any) => AddImageSelector(state).imageList,
    shallowEqual
  );
  const allImageResult = useSelector(
    (state: any) => AddImageSelector(state).allImageResult,
    shallowEqual
  );

  useEffect(() => {
    if (!allImageResult || allImageResult?.length <= 0) {
      setShowMoreSearch(false);
      return;
    } else {
      let count = imageList?.length ?? 0;

      if (count >= allImageResult.length) {
        setShowMoreSearch(false);
        return;
      }
    }

    setShowMoreSearch(true);
  }, [imageList, allImageResult]);

  const generatedImages = useSelector(
    (state: any) => AddImageSelector(state).generatedImages,
    shallowEqual
  );

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ImageSectionPanel
        title={t('image.myImages')}
        expandTitle={t('image.editPrompt')}
        moreTitle={t('image.searchMore')}
        contentCreator={(props) => <ImageUploadCompWrapper {...props} />}
        onMoreAction={
          showMoreSearch
            ? () => dispatch(showMoreImageSearchResults())
            : undefined
        }
        id="image-search-section"
      />
      <Box
        sx={{
          mt: 7,
          mb: 2,
        }}
      >
        <ImageSectionPanel
          title={t('image.AIImages')}
          expandTitle={t('image.editPrompt')}
          moreTitle={t('image.generateMore')}
          contentCreator={(props) => <ImageGenerationWrapper {...props} />}
          onMoreAction={
            generatedImages && generatedImages.length > 0
              ? () => dispatch(generateImages(false))
              : undefined
          }
          id="ai-image-section"
        />
      </Box>
    </Box>
  );
};

export default ImageSelectionComp;
