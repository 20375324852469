/* eslint-disable @typescript-eslint/no-unused-vars */
import editorTheme from './editorTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import './TextStyle.css';
import './formatStyle.css';
import {
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  EditorState,
  FOCUS_COMMAND,
  LexicalEditor,
} from 'lexical';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CardCover from '@mui/joy/CardCover';
import { toolbarItemTypes } from './fixedData';
import CharactersCount from './features/CharactersCount';
import ConfirmModelComp from '../confirmModelComp/ConfirmModelComp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { checkSwitch } from 'src/utils/checkSwitch';
import { isEmpty, isNumber } from 'lodash';
import { ResizeCallbackData, ResizableBox } from 'react-resizable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  resizeTextAreaSync,
  selectedNodeKey,
} from 'src/redux/appSetting/actions';
import { configSelector } from 'src/redux/appSetting/selector';
import ComplexityScore from './features/ComplexityScore';
import { LayoutContainerNode } from './nodes/LayoutContainerNode';
import { LayoutItemNode } from './nodes/LayoutItemNode';
import ImagesPlugin from './plugins/ImagesPlugin';
import { ImageNode } from './nodes/ImageNode';
import { updateEmptyInputValue } from 'src/redux/translation/actions';
import ClearPluginWrapper from './plugins/ClearPluginWrapper';
import { PASTE_COMMAND } from 'lexical';
import {
  DELETE_TABLE_COMMAND,
  deleteTable,
  SPLIT_TABLE_COMMAND,
  splitTableAtCursor,
} from './plugins/splitAndMergeText';
import { useSettings } from './context/SettingsContext';
import TableHoverActionsPlugin from './plugins/TableHoverActionsPlugin';
import { getSelectedText } from 'src/components/TextareaField/helper';

function Placeholder({
  text,
  placeholderStyle,
}: {
  text: string;
  placeholderStyle: object;
}) {
  return (
    <div
      className={'editor-placeholder'}
      style={placeholderStyle}
    >
      {text}
    </div>
  );
}

const editorConfig = {
  namespace: 'summ-ai',
  // The editor theme
  theme: editorTheme,
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },

  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    LayoutContainerNode,
    LayoutItemNode,
  ],
};

type Props = {
  disableInputField: boolean;
  inputText: string;
  refEditor?: RefObject<LexicalEditor>;
  containerStyle?: object;
  textValue?: any;
  clearEditor?: boolean;
  placeholder: string;
  loader?: boolean;
  id?: string;
  featureInclude?: toolbarItemTypes;
  toolbarStyle?: object;
  placeholderStyle?: object;
  editorStyle?: object;
  editorScrollStyle?: object;
  editorInnerStyle?: object;
  noAnimationSlider?: boolean;
  counterTitle?: string;
  showCounter?: boolean;
  textCharactersColor?: string;
  textComplexityColor?: string;

  // Show toolbar and formatter
  showToolbar?: boolean;

  // Setting options
  settingComponent?: React.ReactNode;
  showSwitch?: boolean;
  showFormatIcon?: boolean;
  editable?: boolean;

  // store text value in state
  complexityScore?: string;
  complexityScoreTitle?: string;
  comlexityScoreDescription?: string;
  axiosResize?: any;
  displayImage?: boolean;
  ariaLabel: string;
  switchValue?: boolean;

  // Text watch
  debounceDetectLang?: (text: string) => void;
  handleSwitchFormatting?: (data: boolean) => void;
  textAreaStartingHeight?: number;
  setTextValue?: Dispatch<SetStateAction<string>>;

  // higlight word
  highLightWord?: { [key: string]: string };
  sidebarTab?: number;
  sidebarOpen?: boolean;
  showHighlight?: boolean;
  replaceTextLexical?: (
    refEditor: any,
    textToMatch: {
      [key: string]: string;
    }
  ) => void;
  resetHighlight?: (refEditor: any) => void;
};
const TextEditorComp = ({
  disableInputField,
  inputText,
  refEditor,
  showToolbar = false,
  containerStyle = {},
  textValue,
  clearEditor = true,
  placeholder,
  loader = false,
  id = 'translationInput',
  featureInclude = ['All'],
  toolbarStyle = {},
  placeholderStyle = {},
  editorStyle = {},
  editorScrollStyle = {},
  editorInnerStyle = {},
  noAnimationSlider = false,
  counterTitle = '',
  showCounter = false,
  settingComponent = null,
  showSwitch = false,
  showFormatIcon = true,
  editable = false,
  complexityScore,
  complexityScoreTitle,
  comlexityScoreDescription,
  axiosResize = 'y',
  displayImage = true,
  ariaLabel = 'Textfeld',
  textCharactersColor,
  textComplexityColor,
  debounceDetectLang,
  switchValue = true,
  handleSwitchFormatting,
  textAreaStartingHeight,
  setTextValue,

  highLightWord,
  sidebarOpen,
  sidebarTab,
  showHighlight,
  replaceTextLexical,
  resetHighlight,
}: Props) => {
  const containerRef: any = useRef(null);
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const { t } = useTranslation(['common', 'easy_language', 'A11y'], {
    nsMode: 'fallback',
  });
  const [counter, setCounter] = useState<number>(0);
  const [overflowYEleme, setOverflowY] = useState<
    'auto' | 'hidden' | undefined
  >('auto');
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null);
  const [switchInput, setSwitchInput] = useState<string>('off');

  const [checked, setChecked] = useState(switchValue);
  const [open, setOpen] = useState<boolean>(false);
  const [showModelAgain, setShowModelAgain] = useState(
    JSON.parse(localStorage?.getItem('showModelAgain') ?? 'true')
  );

  const textareaDimension = useSelector(
    (state: any) => configSelector(state).textareaDimension,
    shallowEqual
  );

  const textAreaHeight = textAreaStartingHeight ?? textareaDimension.height;

  useEffect(() => {
    if (disableInputField) {
      setShowSetting(false);
    }
  }, [disableInputField]);

  useEffect(() => {
    setSwitchInput(switchValue ? 'on' : 'off');
  }, [switchValue]);

  // save selected key in session storage and update the style if needed

  useEffect(() => {
    if (refEditor && refEditor?.current) {
      refEditor?.current?.registerUpdateListener((props: any) => {
        // Highlight word node key
        dispatch(selectedNodeKey([...props.dirtyLeaves][0]));

        props.editorState?.read(() => {
          if (showCounter) {
            setCounter($getRoot().getTextContent().length);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refEditor, showCounter]);

  useEffect(() => {
    refEditor?.current?.registerCommand(
      FOCUS_COMMAND,
      (e: any) => {
        e.preventDefault();
        return true;
      },
      COMMAND_PRIORITY_HIGH
    );
    refEditor?.current?.registerCommand(
      PASTE_COMMAND,
      (payload: any) => {
        let textCopied = payload?.clipboardData?.getData('text');
        const selection = $getSelection();
        const isRangeSelection = $isRangeSelection(selection);
        if (isRangeSelection) {
          // Insert the modified text in place of the selection or caret
          if (sessionStorage.getItem('sidebar') === 'true') {
            refEditor?.current?.update(() => {
              let paragraph = $createParagraphNode();
              paragraph.append($createTextNode(textCopied));
              selection.insertNodes([paragraph]);
            });
          }

          if (sessionStorage.getItem('sidebar') === 'true') {
            sessionStorage.removeItem('sidebar');
            return true;
          }
          return false; // Prevent default text insertion
        }
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [refEditor]);

  // Split paragraph and merge paragraph
  useEffect(() => {
    if (!refEditor?.current) return;
    // Register commands
    refEditor.current.registerCommand(
      SPLIT_TABLE_COMMAND,
      () => {
        splitTableAtCursor(refEditor.current);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );

    refEditor.current.registerCommand(
      DELETE_TABLE_COMMAND,
      () => {
        deleteTable(refEditor.current);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [id, refEditor]);

  const handleShowSetting = () => {
    setShowSetting(!showSetting);
    setOverflowY(showSetting ? 'auto' : 'hidden');
  };

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const handleCopyText = async () => {
    const elementSelect: any = document.querySelector(`#${id}`);
    let inputTextUpdate = elementSelect.innerHTML?.replace(
      /#edc34775/g,
      'rgb(220 226 230 / 3%)'
    );

    // replace cell style to have no border
    inputTextUpdate = inputTextUpdate.replaceAll(
      'border: 1px solid black',
      'border: none'
    );
    const htmlWrapper = (html: string) =>
      `<div style="font-family:Arial; line-height:auto;">${html}</div>`;
    try {
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob(
          [
            switchInput === 'on'
              ? htmlWrapper(elementSelect?.innerText)
              : htmlWrapper(inputTextUpdate),
          ],
          {
            type: 'text/plain',
          }
        ),
        'text/html': new Blob(
          [
            switchInput === 'on'
              ? htmlWrapper(inputTextUpdate)
              : htmlWrapper(elementSelect?.innerHTML?.replace(/\n/g, '<br/>')),
          ],
          { type: 'text/html' }
        ),
      });
      await navigator?.clipboard?.write([clipboardItem]);
    } catch (err) {
      await navigator?.clipboard?.writeText(inputTextUpdate);
    }
  };
  // Control switch functionality
  const handleSwitch = () => {
    if (showModelAgain) {
      setOpen(true);
    } else {
      handleConfirmSwitch();
    }
  };

  const handleUpdateText = () => {
    refEditor?.current?.update(() => {
      let text = document.getElementById(id)?.innerText;
      if (!isEmpty(text) && text !== '\n') {
        $getRoot().clear();
        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const paragraph = $createParagraphNode();
        const textVal = $createTextNode(text);
        textVal.__text = textVal.__text?.replace(/(\r?\n|\r){2,}/g, '\n\n');
        paragraph.append(textVal);
        $getRoot().append(paragraph);
        $getRoot().selectEnd();
      }
    });
  };
  const handleConfirmSwitch = () => {
    let switchVal = switchInput === 'on' ? 'off' : 'on';
    setOpen(false);
    setSwitchInput(switchVal);
    setChecked(!checked);
    if (handleSwitchFormatting) {
      handleSwitchFormatting(!checked);
    }
    setShowModelAgain(
      JSON.parse(localStorage?.getItem('showModelAgain') ?? 'true')
    );
    handleUpdateText();
  };

  const handleShowModelAgain = (e: any) => {
    localStorage.setItem('showModelAgain', `${!e?.target.checked}`);
  };

  const handleResizeUpdate = (e: SyntheticEvent, data: ResizeCallbackData) => {
    dispatch(
      resizeTextAreaSync({ width: data.size.width, height: data.size.height })
    );
  };

  // const applyStyleText = useCallback(
  //   (styles: Record<string, string>) => {
  //     refEditor?.current?.update(() => {
  //       const selection = $getSelection();
  //       if ($isRangeSelection(selection)) {
  //         $patchStyleText(selection, styles);
  //       }
  //     });
  //   },
  //   [refEditor]
  // );

  return (
    // <FocusTrap open>
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editable: !disableInputField && editable,
        namespace: id,
        nodes: [...editorConfig.nodes, ...(displayImage ? [ImageNode] : [])],
      }}
    >
      <div
        className={'editor-container'}
        ref={containerRef}
        style={{ ...containerStyle, position: 'relative' }}
      >
        <ResizableBox
          className={'box-style'}
          height={textAreaHeight}
          width={textareaDimension.width}
          minConstraints={[756, 373]}
          axis={axiosResize}
          onResize={handleResizeUpdate}
          maxConstraints={[756, 600]}
          {...(axiosResize === 'none' ? { handle: <></> } : {})}
        >
          <>
            <div
              className={'editor-inner'}
              style={{
                ...editorInnerStyle,
                height: `calc(${textAreaHeight}px - 51px)`,
                overflowY: overflowYEleme,
              }}
              id={id + '-container'}
            >
              {switchInput === 'on' ? (
                <RichTextPlugin
                  contentEditable={
                    <div
                      className={'editor-scroller'}
                      style={editorScrollStyle}
                      id="editor-scroller"
                    >
                      <div
                        className={'editor'}
                        ref={onRef}
                      >
                        <ContentEditable
                          className={'editor-input'}
                          spellCheck={true}
                          autoCapitalize="none"
                          value={inputText}
                          id={id}
                          style={editorStyle}
                          ariaLabel={ariaLabel}
                          name={id}
                        />
                        <TableHoverActionsPlugin />
                      </div>
                    </div>
                  }
                  placeholder={
                    <Placeholder
                      text={placeholder}
                      placeholderStyle={placeholderStyle}
                    />
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />
              ) : (
                <PlainTextPlugin
                  contentEditable={
                    <div
                      className={'editor-scroller'}
                      style={editorScrollStyle}
                    >
                      <div
                        className={'editor'}
                        ref={onRef}
                      >
                        <ContentEditable
                          className={'editor-input'}
                          spellCheck={true}
                          autoCapitalize="none"
                          value={inputText}
                          id={id}
                          style={editorStyle}
                          ariaLabel={ariaLabel}
                          name={id}
                        />
                      </div>
                    </div>
                  }
                  placeholder={
                    <Placeholder
                      text={placeholder}
                      placeholderStyle={placeholderStyle}
                    />
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />
              )}
              {showSetting && settingComponent}
              <CodeHighlightPlugin />
              <ListPlugin />
              <LinkPlugin />
              {displayImage && <ImagesPlugin />}
              {clearEditor && <ClearPluginWrapper />}
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              <HistoryPlugin />

              {floatingAnchorElem && (
                <>
                  <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                </>
              )}
              {/* {removeEditorFormat && <RemoveTextFormat />} */}
              <OnChangePlugin
                onChange={(
                  editorState: EditorState,
                  editor: LexicalEditor,
                  tags
                ) => {
                  editorState?.read(() => {
                    // console.log('editorState', $getRoot().getTextContent());
                    if (textValue && textValue.current) {
                      if (
                        debounceDetectLang &&
                        $getRoot().getTextContent() !==
                          textValue.current.text &&
                        !isEmpty($getRoot().getTextContent())
                      ) {
                        debounceDetectLang($getRoot().getTextContent() || '');
                      }
                      textValue.current.text = $getRoot().getTextContent();

                      if (setTextValue) {
                        setTextValue($getRoot().getTextContent());
                      }

                      if (id === 'translation-input') {
                        if (!isEmpty($getRoot().getTextContent())) {
                          return dispatch(updateEmptyInputValue(false));
                        } else {
                          return dispatch(updateEmptyInputValue(true));
                        }
                      }
                    }
                  });
                }}
              />
              {disableInputField && loader && (
                <CardCover sx={{ background: theme.palette.general.overlay }}>
                  <Box sx={{ width: '100%', height: '100%' }}>
                    <LinearProgress
                      sx={{
                        position: 'absolute',
                        width: '60%',
                        background: theme.palette.primary.main,
                        '& .MuiLinearProgress-bar': {
                          background: theme.palette.secondary.main,
                        },
                      }}
                      role="progressbar"
                      aria-label={t('translation_loading')}
                    />
                  </Box>
                </CardCover>
              )}
            </div>
            <ToolbarPlugin
              refHolder={containerRef}
              text={inputText}
              refEditor={refEditor}
              showToolbar={showToolbar}
              handleCopyText={handleCopyText}
              featureInclude={featureInclude}
              toolbarStyle={toolbarStyle}
              noAnimationSlider={noAnimationSlider}
              handleShowSetting={handleShowSetting}
              settingComponent={settingComponent}
              showSetting={showSetting}
              handleSwitch={handleSwitch}
              switchInput={switchInput}
              switchChecked={checked}
              showSwitch={showSwitch}
              showFormatIcon={showFormatIcon}
              disabled={editable}
              id={id}
            />
            {showCounter && (
              <CharactersCount
                input={counter}
                titleText={counterTitle}
                textCharactersColor={textCharactersColor}
              />
            )}
            {complexityScore && (
              <ComplexityScore
                input={complexityScore}
                titleText={complexityScoreTitle}
                description={comlexityScoreDescription}
                textComplexityColor={textComplexityColor}
              />
            )}
          </>
        </ResizableBox>
      </div>
      {showModelAgain ? (
        <ConfirmModelComp
          open={open}
          setOpen={setOpen}
          title={t(
            checkSwitch(switchValue) === 'on'
              ? 'switchModelTitleOn'
              : 'switchModelTitleOff'
          )}
          msg={t(checkSwitch(switchValue) === 'on' ? 'msgOn' : 'msgOff')}
          handleConfirm={handleConfirmSwitch}
          children={
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleShowModelAgain}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fill: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={t('dontShowThisText')}
            />
          }
          confirmText={
            checkSwitch(switchValue) === 'on'
              ? t('modelConfirmOn')
              : t('modelConfirmOff')
          }
        />
      ) : (
        <></>
      )}
    </LexicalComposer>
    // </FocusTrap>
  );
};

export default TextEditorComp;
