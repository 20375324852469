import { FC, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserInfo } from '../../redux/user/actions';
import { UserSelector } from '../../redux/user/selector';
import OptinWaitingSupabase from '../optinWaitingPage/OptinWaitingSupabase';
import LogoutButton from '../utils/LogoutButton';
import { clearLocalStorage } from '../utils/utils';
import HelpSection from './HelpSection';
import UpdateAppPopup from './Modals/UpdateAppPopup';
import WhatsnewPopup from './Modals/WhatsnewPopup';
import BetaListWarning from './Warnings/BetaListWarning';
import LicenceExpiredWarning from './Warnings/LicenceExpiredWarning';
import ServerDownWarning from './Warnings/ServerDownWarning';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { Button, Container, useTheme } from '@mui/material';
import { setMatomoUid } from 'src/utils/matomo';
import { newLineSet } from 'src/redux/translation/actions';
import { numberFormatter } from 'src/utils/formatNumber';
import { getShareGlossaryEntites } from 'src/redux/glossaryEntries/actions';
import LoadingBanner from 'src/coreUI/loadingBanner/LoadingBanner';

const Dashboard: FC = () => {
  const theme = useTheme();
  const [showUpdateAppPopup, setShowUpdateAppPopup] = useState(false);
  const dispatch: any = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const { t } = useTranslation(['dashboard', 'glossary'], {
    nsMode: 'fallback',
  });
  const userDetails: any = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );
  const loading = useSelector(
    (state: any) => UserSelector(state).loading,
    shallowEqual
  );

  const userExist = useSelector(
    (state: any) => UserSelector(state).userExist,
    shallowEqual
  );

  //   const { setuserDetails } = useAuth();
  const history = useNavigate();

  const [userRole, setUserRole] = useState(false);
  const supabaseSession = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  useEffect(() => {
    if (!supabaseSession?.access_token) {
      return;
    }
    if (
      !localStorage.getItem('signup_form') &&
      userExist &&
      localStorage.getItem('token')
    ) {
      dispatch(getUserDetails());
    }

    newLineSet(localStorage.getItem('isNewLine') === 'false' ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseSession?.access_token, userExist]);

  const checkAppOutdated: any = useCallback(() => {
    // check if app needs to be updated
    if (userDetails && userDetails?.appversion) {
      let responseAppVersion = userDetails?.appversion;
      const appVersion = localStorage.getItem('appversion');
      if (appVersion !== null) {
        if (appVersion !== responseAppVersion) {
          setShowUpdateAppPopup(true);
        }
      } else {
        localStorage.setItem('appversion', responseAppVersion);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (localStorage.getItem('IN_PURCHASE_FLOW') === 'true') {
      return history('/checkout');
    }

    if (localStorage.getItem('signup_form')) {
      return history('/signup');
    }

    // if (!userDetails) {
    //   localStorage.removeItem('token');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails && localStorage.getItem('token')) {
      checkAppOutdated();
      setUserRole(userDetails?.userrole === 'admin');
      setMatomoUid(userDetails?.email);

      // pushInstruction('setUserId', userDetails?.email);
      setModalShow(!userDetails?.hasreadwhatsnew);
      dispatch(getShareGlossaryEntites());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleTranslateTextClick = () => {
    history('/easy-language');
  };

  const handleTranslationHistoryClick = () => {
    // trackEvent({
    //   category: 'Dashboard',
    //   action: 'click-translation-history',
    // });

    history('/translation-history');
  };

  const closeWhatsNewPopup = async (flag: boolean) => {
    dispatch(updateUserInfo({ hasreadwhatsnew: flag }));
    setModalShow(false);
  };

  const checkIfEmailIsNotVerified = () => {
    if (supabaseUser?.created_at) {
      return false;
    }
    return true;
  };

  return (
    <Container>
      <div>
        {loading ? (
          <LoadingBanner />
        ) : userDetails ? (
          checkIfEmailIsNotVerified() ? (
            <OptinWaitingSupabase />
          ) : userDetails?.betauser ? (
            userDetails?.hasactivelicence ? (
              <>
                <WhatsnewPopup
                  show={modalShow}
                  close={closeWhatsNewPopup}
                  onClick={() => closeWhatsNewPopup(true)}
                />

                <UpdateAppPopup
                  show={showUpdateAppPopup}
                  onClick={clearLocalStorage}
                />
                <main>
                  <h1
                    className="mt-5"
                    style={{ fontSize: '1.5rem' }}
                  >
                    {t('headertext.welcome')}
                    <br /> {supabaseUser?.email}
                  </h1>
                  <h5>{t('headertext.whattodo')}</h5>

                  <Row>
                    <Col
                      sm={{ span: 8, offset: 2 }}
                      md={{ span: 6, offset: 3 }}
                      lg={{ span: 6, offset: 3 }}
                      xl={{ span: 4, offset: 4 }}
                    >
                      <Button
                        variant="contained"
                        className="w-50 text-center mt-4"
                        type="submit"
                        onClick={handleTranslateTextClick}
                        sx={{
                          textTransform: 'capitalize',
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.secondary.main,
                          p: '.7em',
                          '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                          },
                        }}
                      >
                        {t('buttons.newtranslation')}
                      </Button>
                      <div></div>
                      <Button
                        variant="outlined"
                        className="w-50 text-center mt-4"
                        type="submit"
                        onClick={handleTranslationHistoryClick}
                        sx={{
                          textTransform: 'capitalize',
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          p: '.6em',
                          '&:hover': {
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      >
                        {t('buttons.translationhistory')}
                      </Button>
                      <div></div>
                      <Button
                        variant="outlined"
                        className="w-50 text-center mt-4"
                        type="submit"
                        onClick={() => history('/glossary-entries')}
                        sx={{
                          textTransform: 'capitalize',
                          color: theme.palette.primary.main,
                          borderColor: theme.palette.primary.main,
                          p: '.6em',
                          '&:hover': {
                            borderColor: theme.palette.primary.main,
                          },
                        }}
                      >
                        {t('buttons.glossaryentries')}
                      </Button>
                      <div></div>
                      {/* TODO: remove admin pages */}
                      {false && userRole ? (
                        <Button
                          variant="outlined"
                          className="w-50 text-center mt-4"
                          type="submit"
                          onClick={() => history('/admin')}
                          sx={{
                            textTransform: 'capitalize',
                            color: theme.palette.primary.main,
                            borderColor: theme.palette.primary.main,
                            p: '.6em',
                            '&:hover': {
                              borderColor: theme.palette.primary.main,
                            },
                          }}
                        >
                          {t('buttons.admin')}
                        </Button>
                      ) : (
                        <></>
                      )}

                      <div className="mt-5">
                        <p
                          style={{ color: theme.palette.primary.main }}
                          className="mb-0"
                        >
                          <br />
                          {t('quotaused')}: <br />
                          {userDetails?.quotaspent
                            ? numberFormatter(userDetails?.quotaspent)
                            : '0'}{' '}
                          / {numberFormatter(userDetails?.quotalimit)} <br />
                          {userDetails?.hasindividualquota
                            ? ''
                            : `(${t('companyquota')})`}
                        </p>
                      </div>

                      {/* tmp hide image quota */}
                      <div style={{ display: 'none' }}>
                        <p style={{ color: theme.palette.primary.main }}>
                          <br />
                          {t('imageQuotaUsed')}: <br />
                          {userDetails?.imageQuotaSpent
                            ? numberFormatter(userDetails?.imageQuotaSpent)
                            : '0'}{' '}
                          / {numberFormatter(userDetails?.imageQuotaLimit)}{' '}
                          <br />
                          {userDetails?.hasimageindividualquota
                            ? ''
                            : `(${t('companyquota')})`}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ span: 4, offset: 4 }}
                      className="mt-5"
                    >
                      <LogoutButton />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ span: 4, offset: 4 }}
                      className="mt-2"
                    >
                      <HelpSection />
                    </Col>
                  </Row>
                </main>
              </>
            ) : (
              <LicenceExpiredWarning />
            )
          ) : (
            <BetaListWarning />
          )
        ) : (
          <ServerDownWarning />
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
