import {
  SEND_FEEDBACK,
  LOADING,
  STOP_LOADING,
  SET_RATING_MSG,
  GET_RATING_FEEDBACK,
  SET_RATING_SNACKBAR_KEY,
  SET_RATING_FEEDBACK_STEP,
} from './types';

const initialState: any = {
  feedback: false,
  loading: false,
  ratingMsg: '',
  showRating: false,
  ratingVal: 1,
  ratingSnackbarKey: null,
  ratingStep: 1,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case SEND_FEEDBACK:
      return {
        ...state,
        feedback: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_RATING_MSG:
      return {
        ...state,
        ratingMsg: payload.msg,
        ratingVal: payload.rating,
      };
    case GET_RATING_FEEDBACK:
      return {
        ...state,
        showRating: payload.show_snackbar,
        ratingMsg: 'rating_question',
      };
    case SET_RATING_SNACKBAR_KEY:
      return {
        ...state,
        ratingSnackbarKey: payload,
      };
    case SET_RATING_FEEDBACK_STEP:
      return {
        ...state,
        ratingStep: payload,
      };
    default:
      return state;
  }
}
