import { useState } from 'react';
import RatingNumber from './RatingNumbers';
import RatingInput from './RatingInput';
import RatingRefer from './RatingRefer';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  setRatingFeedbackStep,
  setRatingMsg,
} from 'src/redux/feedback/actions';

type Props = {};
const RatingStepper = (props: Props) => {
  const [step, setStep] = useState(1);
  const dispatch: any = useDispatch();
  const [rating, setRating] = useState<number | null>(null);

  const handleRatingSelect = (selectedRating: number) => {
    dispatch(
      setRatingMsg({ msg: 'feedback_thank_you', rating: selectedRating })
    );
    setRating(selectedRating);
    dispatch(setRatingFeedbackStep(2));
    setStep(2);
  };

  return (
    <Box sx={{ p: 1 }}>
      {step === 1 && <RatingNumber handleRatingSelect={handleRatingSelect} />}
      {step === 2 && rating !== null && (
        <Box>
          {rating < 7 ? <RatingInput rating={rating} /> : <RatingRefer />}
        </Box>
      )}
    </Box>
  );
};

export default RatingStepper;
