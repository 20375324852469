import {
  LOADING,
  STOP_LOADING,
  GET_HISTORY_CHAT,
  START_NEW_CHAT,
  SEND_CHAT_MSG,
  RESET_STATE_VALUE,
} from './types';
import Sentry from '../../config/sentryConfig';
import chatbotService from 'src/services/chatbot.service';
import { ChatbotReducer } from './selector';

export const getHistoryChat = () => async (dispatch: any) => {
  try {
    const res = await chatbotService.getHistoryAPi();
    return dispatch({
      type: GET_HISTORY_CHAT,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get history chatbot error: ${e}`, 'error');
  }
};

export const startNewChat = (ruleId: string) => async (dispatch: any) => {
  try {
    const res = await chatbotService.startNewChatApi(ruleId);
    localStorage.setItem('chatId', res.data.chat_id);
    return dispatch({
      type: START_NEW_CHAT,
      payload: { chatId: res.data.chat_id, ruleId },
    });
  } catch (e: any) {
    Sentry.captureMessage(`Start new chatbot error: ${e}`, 'error');
    // return appMessageHandling(dispatch, 'error.update_features_error', 'error');
  }
};

export const sendChatbotMsg = (message: string) => async (dispatch: any) => {
  try {
    // save user message
    dispatch({
      type: SEND_CHAT_MSG,
      payload: { message, role: 'user' },
    });

    // placeholder for loading
    dispatch({
      type: SEND_CHAT_MSG,
      payload: { message: 'loading message', role: 'assistant' },
    });

    dispatch(loading());
    const res = await chatbotService.sendChatMsgApi(message);

    // save chatbot message
    return dispatch({
      type: SEND_CHAT_MSG,
      payload: { ...res.data, new: true },
    });
  } catch (e: any) {
    Sentry.captureMessage(`Send msg chatbot error: ${e}`, 'error');
    // return appMessageHandling(dispatch, 'error.update_features_error', 'error');
  } finally {
    dispatch(stopLoading());
  }
};
export type PartialChatbotReducer = Partial<ChatbotReducer>;
export const resetStateValues =
  (data: PartialChatbotReducer) => (dispatch: any) => {
    return dispatch({
      type: RESET_STATE_VALUE,
      payload: data,
    });
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};
